import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AlertService } from '../alert.service';
import { ChartSearch } from '../../models/chart/chart.model';

@Injectable()
export class ChartEndpoint extends EndpointBase {

    private readonly urlPart: string = "/api/Charts";

    get url() { return this.configurations.baseUrl + this.urlPart; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }


    getChartsEndpoint<T>(search: ChartSearch): Observable<T> {
        let endpointUrl = this.url;
        if (search) endpointUrl = endpointUrl + `?processCode=${search.processCode}&processCodeId=${search.processCodeId}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getChartsEndpoint(search));
            }));
    }
    getChartEndpoint<T>(id: number): Observable<T> {
        let endpointUrl = this.url+`/${id}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getChartEndpoint(id));
            }));
    }
  
    getDeleteChartEndpoint<T>(id): Observable<T> {
        let endpointUrl = this.url + `/${id}`;
        return this.http.delete<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getDeleteChartEndpoint(id));
            }));
    }
    getAddChartEndpoint<T>(item: any): Observable<T> {
        let endpointUrl = this.url ;
        return this.http.post<T>(endpointUrl, JSON.stringify(item), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getAddChartEndpoint(item));
            }));
    }
    getUpdateChartEndpoint<T>(id, item): Observable<T> {
        let endpointUrl = this.url + `/${id}`;
        return this.http.put<T>(endpointUrl, JSON.stringify(item), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdateChartEndpoint(id, item));
            }));
    }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PortfolioModel } from '../../models/company/portfolio.model';
import { ProductEndpoint } from './product-endpoint.service';
import { ContractType } from '../../models/product/contract-type.model';
import { Rider } from '../../models/product/rider.model';
import { Product } from '../../models/product/product.model';

@Injectable()
export class ProductService {
    constructor(private productEndpoint: ProductEndpoint) {

    }

    getContracts() {
        return this.productEndpoint.getContractsEndpoint<ContractType[]>();
    }
    getAvailableContracts() {
        return this.productEndpoint.getAvailableContractsEndpoint<ContractType[]>();
    }
    addContract(item: ContractType) {
        return this.productEndpoint.addContractEndpoint<ContractType>(item);
    }
    deleteContract(id: number) {
        return this.productEndpoint.deleteContractEndpoint<ContractType>(id);
    }
    deleteProduct(id: number, contacrId: number) {
        return this.productEndpoint.deleteProductEndpoint<Product>(id, contacrId);
    }
    deleteRider(id: number, productId: number, contacrId: number) {
        return this.productEndpoint.deleteRiderEndpoint<Rider>(id, productId, contacrId);
    }
    
    
    updateContract( item: ContractType) {
        return this.productEndpoint.updateContractEndpoint<ContractType>(item.id, item);
    }

}

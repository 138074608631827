/// <reference types="@angular/localize" />
import { appConfig } from './app/app.config';

import { enableProdMode, ErrorHandler, Injectable, Inject, InjectionToken, importProvidersFrom } from '@angular/core';

import { getBaseUrl, TimeoutInterceptor } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/components/app.component';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { UserIdleModule } from './app/services/user-idle/user-idle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ControlsModule } from './app/components/controls/controls.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { FormsModule } from '@angular/forms';
import { OAuthModule } from 'angular-oauth2-oidc';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { DynamicScriptLoaderService } from './app/services/script/dynamic-script-loader.service';
import { ChartService } from './app/services/chart/chart.service';
import { ChartEndpoint } from './app/services/chart/chart-endpoint.service';
import { EtlService } from './app/services/etl/etl.service';
import { EtlEndpoint } from './app/services/etl/etl-endpoint.service';
import { RemoteServerService } from './app/services/remote/remote-server.service';
import { UserSettingsEndpoint } from './app/services/company/settings-endpoint.service';
import { SettingsService } from './app/services/company/settings.service';
import { FileUploadService } from './app/services/file-upload.service';
import { FileTemplateService } from './app/services/template/file-template.service';
import { FileTemplateEndpoint } from './app/services/template/file-template-endpoint.service';
import { GroupPermissionsEndpoint } from './app/services/company/group-permissions-endpoint.service';
import { GroupPermissionsService } from './app/services/company/group-permissions.service';
import { OidcHelperService } from './app/services/oidc-helper.service';
import { CompanyEndpoint } from './app/services/company/company-endpoint.service';
import { CompanyService } from './app/services/company/company.service';
import { EndpointBase } from './app/services/endpoint-base.service';
import { LocalStoreManager } from './app/services/local-store-manager.service';
import { FileEndpoint } from './app/services/file-endpoint.service';
import { FileService } from './app/services/file.service';
import { UserEndpoint } from './app/services/company/user-endpoint.service';
import { UserService } from './app/services/company/user.service';
import { SecurityEndpoint } from './app/services/company/security-endpoint.service';
import { SecurityService } from './app/services/company/security.service';
import { ProductEndpoint } from './app/services/company/product-endpoint.service';
import { ProductService } from './app/services/company/product.service';
import { AccountEndpoint } from './app/services/account-endpoint.service';
import { AccountService } from './app/services/account.service';
import { NotificationEndpoint } from './app/services/notification-endpoint.service';
import { NotificationService } from './app/services/notification.service';
import { AppTranslationService, TranslateLanguageLoader } from './app/services/app-translation.service';
import { AppTitleService } from './app/services/app-title.service';
import { ConfigurationService } from './app/services/configuration.service';
import { AlertService } from './app/services/alert.service';
import { Observable, timeout } from 'rxjs';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppErrorHandler } from './app/app-error.handler';

const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
// const timeout_number = Number(req.headers.get('timeout'));
const defaultTimeout = 40000;



if (environment.production) {
  enableProdMode();
}
const global = globalThis;

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));

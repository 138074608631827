import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { provideToastr } from 'ngx-toastr';

import { routes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLanguageLoader } from './services/app-translation.service';
import { FormsModule } from '@angular/forms';

 import { NgbModalModule, NgbTooltipModule, NgbPopoverModule, NgbDropdownModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

// import { ChartsModule } from 'ng2-charts';
// import { DndModule } from 'ng2-dnd';

import { AppErrorHandler } from './app-error.handler';
import { AppTitleService } from './services/app-title.service';
import { AppTranslationService,  } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { EndpointBase } from './services/endpoint-base.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';
import { CompanyService } from './services/company/company.service';
import { CompanyEndpoint } from './services/company/company-endpoint.service';
import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
//import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
// import { BootstrapToggleDirective } from './directives/bootstrap-toggle.directive';
import { MainMenuDirective } from './directives/main-menu.directive';
//import { BootstrapSelectDirective } from './directives/bootstrap-select.directive';

import { AppComponent } from "./components/app.component";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
// import { CustomersComponent } from "./components/customers/customers.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";

import { NotificationsViewerComponent } from "./components/controls/notifications-viewer.component";
import { SearchBoxComponent } from "./components/controls/search-box.component";
// import { UserInfoComponent } from "./components/controls/user-info.component";
// import { UsersManagementComponent } from "./components/controls/users-management.component";
// import { UserPreferencesComponent } from "./components/controls/user-preferences.component";
// import { RolesManagementComponent } from "./components/controls/roles-management.component";
// import { RoleEditorComponent } from "./components/controls/role-editor.component";
import { FileService } from "./services/file.service";
import { FileEndpoint } from "./services/file-endpoint.service";



import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { ProductService } from "./services/company/product.service";
import { ProductEndpoint } from "./services/company/product-endpoint.service";
import { SecurityService } from "./services/company/security.service";
import { SecurityEndpoint } from "./services/company/security-endpoint.service";
import { UserService } from "./services/company/user.service";
import { UserEndpoint } from "./services/company/user-endpoint.service";
import { GroupPermissionsService } from './services/company/group-permissions.service';
import { GroupPermissionsEndpoint } from './services/company/group-permissions-endpoint.service';
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";


import { ChatBotComponent } from "./components/chatbot/chatbot.component";
import { SimpleBarDirective } from "./directives/simple-bar.directive";
import { ChatComponent } from "./components/chatbot/chat.component";
import { UpdateUsersComponent } from "./components/controls/chat/update-users.component";
import { UpdateTitleComponent } from "./components/controls/chat/update-title.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";

//Liability Assets

import { UserIdleModule } from "./services/user-idle/user-idle";

import { FileTemplateService } from "./services/template/file-template.service";
import { FileTemplateEndpoint } from "./services/template/file-template-endpoint.service";

import { Bsplitter1Component } from './components/controls/calc/bsplitter1/bsplitter1.component';
import { FileUploadService } from './services/file-upload.service';

import { SettingsService } from "./services/company/settings.service";
import { UserSettingsEndpoint } from "./services/company/settings-endpoint.service";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import 'hammerjs';
import { GridModule } from '@progress/kendo-angular-grid';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';


import { BiAnalyticsComponent } from "./components/bi-analytics/bi-analytics.component";

import { HighlightCodeDirective } from "./directives/highlight-code.directive";

//import { Chart2dComponent } from "./components/controls/Charts/chart2d.component";

import { RemoteServerService } from "./services/remote/remote-server.service";
import { RemoteServerServiceEndpoint } from "./services/remote/remote-server-endpoint.service.ts";

import { Observable } from 'rxjs';
import { timeout } from 'rxjs';

import { Injectable, Injector, InjectionToken, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ChartService } from "./services/chart/chart.service";
import { ChartEndpoint } from "./services/chart/chart-endpoint.service";
import { ControlsModule } from "./components/controls/controls.module";


import { EtlService } from "./services/etl/etl.service";
import { EtlEndpoint } from "./services/etl/etl-endpoint.service";
import { DynamicScriptLoaderService } from "./services/script/dynamic-script-loader.service";
import { OidcHelperService } from './services/oidc-helper.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';

const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
const defaultTimeout = 40000;


@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeout_number = Number(req.headers.get('timeout')); //|| this.defaultTimeout;
    if (timeout_number)
      return next.handle(req).pipe(timeout(timeout_number));
    else
      return next.handle(req);
  }
}




export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(), // required animations providers
    provideToastr({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      maxOpened: 100
    }), // Toastr providers
    importProvidersFrom(OAuthModule.forRoot(), FormsModule, 
      TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
      })),
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: defaultTimeout },
    AlertService,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AuthGuard,
    AuthService,
    AccountService,
    AccountEndpoint,
    ProductService,
    ProductEndpoint,
    SecurityService,
    SecurityEndpoint,
    UserService,
    UserEndpoint,
    FileService,
    FileEndpoint,
    LocalStoreManager,
    EndpointBase,
    CompanyService,
    CompanyEndpoint,
    OidcHelperService,
    GroupPermissionsService,
    GroupPermissionsEndpoint,
    FileTemplateEndpoint,
    FileTemplateService,
    FileUploadService,
    SettingsService,
    UserSettingsEndpoint,
    RemoteServerService,
    RemoteServerServiceEndpoint,
    EtlEndpoint,
    EtlService,
    ChartEndpoint,
    ChartService,
    DynamicScriptLoaderService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())

  ]
};

export interface AppSettings {
  dir: 'ltr' | 'rtl';
  theme: string;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  boxed: boolean;
  horizontal: boolean;
  activeTheme: string;
  language: string;
  cardBorder: boolean;
  navPos: 'side' | 'top';
}

export const defaults: AppSettings = {
  dir: 'ltr',
  theme: 'light',
  sidenavOpened: false,
  sidenavCollapsed: false,
  boxed: true,
  horizontal: false,
  cardBorder: false,
  activeTheme: 'blue_theme',
  language: 'en-us',
  navPos: 'side',
};


/*
, {
    providers: [
        importProvidersFrom(BrowserModule, OAuthModule.forRoot(), FormsModule, AppRoutingModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }), 
        // NgxDatatableModule,
        ToastaModule.forRoot(), TooltipModule.forRoot(), PopoverModule.forRoot(), BsDropdownModule.forRoot(), CarouselModule.forRoot(), ModalModule.forRoot(),  ControlsModule, 
        // Need remove
        NgxDatatableModule, MatSelectModule, MatAutocompleteModule, 
        //        MatTableModule,
        //        MatPaginatorModule,
        MatMenuModule, MatIconModule, MatButtonModule, UserIdleModule.forRoot({ idle: 600, timeout: 300, ping: 120 }), ButtonsModule, GridModule, MultiSelectModule),
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: defaultTimeout },
        AlertService,
        ConfigurationService,
        AppTitleService,
        AppTranslationService,
        NotificationService,
        NotificationEndpoint,
        AccountService,
        AccountEndpoint,
        ProductService,
        ProductEndpoint,
        SecurityService,
        SecurityEndpoint,
        UserService,
        UserEndpoint,
        FileService,
        FileEndpoint,
        LocalStoreManager,
        EndpointBase,
        CompanyService,
        CompanyEndpoint,
        OidcHelperService,
        GroupPermissionsService,
        GroupPermissionsEndpoint,
        FileTemplateEndpoint,
        FileTemplateService,
        FileUploadService,
        SettingsService,
        UserSettingsEndpoint,
        RemoteServerService,
        RemoteServerServiceEndpoint,
        EtlEndpoint,
        EtlService,
        ChartEndpoint,
        ChartService,
        DynamicScriptLoaderService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
}
*/

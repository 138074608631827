import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BatchJob, ResultFile, BatchProcessResult } from '../../models/batch/batch-job.model';
import * as moment from 'moment';
import { FileTemplateEndpoint } from './file-template-endpoint.service';
import { TemplateFileModel } from '../../models/files-templates/template-file.model';

@Injectable()
export class FileTemplateService {
    constructor(private fileEndpoint: FileTemplateEndpoint) {

    }

    getList() {
        return this.fileEndpoint.getFilesEndpoint<TemplateFileModel[]>();
    }
    getFile(id: string) {
        return this.fileEndpoint.getFileEndpoint<Blob>(id);
    }
    deleteFiles(ids: string[]) {
        return this.fileEndpoint.getDeleteFilesEndpoint<TemplateFileModel[]>(ids);

    }
    updatInfo(id: string, info: TemplateFileModel) {
        return this.fileEndpoint.getUpdatInfoEndpoint<TemplateFileModel>(id, info);
    }
    
}

import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class FileUploadService {

  constructor () {
    
  }

  progress = 0;


}

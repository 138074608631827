
<h4 class="modal-title pull-left" mat-dialog-title>{{ 'app.PageLoading' | translate }}</h4>

<mat-dialog-content>
  <div class="d-flex justify-content-center">
    <mat-spinner color="accent" diameter="60">
    </mat-spinner>
    </div>

</mat-dialog-content>

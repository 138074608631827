import { UserSettings } from "../../models/user-settings.model";
import { UserSettingsEndpoint } from "./settings-endpoint.service";
import { Injectable } from "@angular/core";
import { TestApiModel, TestModel } from "../../models/settings/test.model";

@Injectable()
export class SettingsService {
    constructor(private settingsEndpoint: UserSettingsEndpoint) {

    }

    getSettings() {
        return this.settingsEndpoint.getSettingsEndpoint<UserSettings>();
    }
    update(settings: UserSettings) {
        return this.settingsEndpoint.getUpdateUserSettingsEndpoint<UserSettings>(settings);
    }
  getFullTests() {
    return this.settingsEndpoint.getFullTestsEndpoint<TestModel[]>();
  }
  startTest(test: TestModel) {
    return this.settingsEndpoint.startTestEndpoint<TestModel>(test);

  }
  startApi(server: string, action:string) {
    return this.settingsEndpoint.startApiEndpoint<TestApiModel>(server, action);

  }
}

import { ConfigurationService } from "../../services/configuration.service";

export class AppUser {
    constructor(public id?: string,
        //TODO:Dublicate
        public pictureFileName?: string,
        public picture?: string,
        public pictureid?: number,
        public groupId?: number,
        public groupName?: string,
        public coUserId?: string,

        public name?: string,
        public userName?: string,
        public firstName?: string,
        public lastName?: string,
        public middleName?: string,
        public companyName?: string,
        public companyId?: number,
        public createdDate?: Date,
        public lockoutEnd?: Date,
        public terminated?: Date,
        public isPending?: boolean,
        public isEnabled?: boolean,
        public isLockedOut?: boolean,
        public status?: string,

        public department?: string,
        public cityName?: string,
        public countryId?: number,

        public languageModelId?: number,
        public employeeTypeCode?: string,
        public timeZoneModelId?: number,
        public phone?: string,
        public title?: string,
        public stateId?: number,
        public pictureId?: number,
        public companiesIds?: number[],
        public email?: string,
        public gender?: string,
        

    ) {
    }
    public static getPictureStyle(user: AppUser, configurations: ConfigurationService) {
        var picture = user.picture ? user.picture : user.pictureFileName;
        return AppUser.getUserPictureStyle(picture, configurations);
    }
    public static getUserPictureStyle(picture: string, configurations: ConfigurationService) {
        if (picture) {
            return `url(${configurations.baseUrl}/Uploads/UserLogo/${picture})`;
        }
        return null;
    }
    public static getPictureLits(user: AppUser) {
        let lits: string = '';
        if (user.pictureFileName) return lits;
        if (user.firstName) lits += user.firstName[0];
        if (user.lastName) lits += user.lastName[0];
        return lits;
    }
    public static getUserNameLits(userName: string) {
        let lits: string = '';
        if (!userName) return lits;
        let names = userName.split(" ");
        if (names[0]) lits += names[0][0];
        if (names.length > 1 && names[1]) lits += names[1][0];
        return lits;
    }
}


export class UserGroupCompany {
    constructor(public id?: string,
        public groupId?: number,
        public companiesIds?: number[],


    ) {
    }
}



import { Injectable, Injector } from "@angular/core";
import { EndpointBase } from "../endpoint-base.service";
import { HttpClient } from "@angular/common/http";
import { ConfigurationService } from "../configuration.service";
import { AlertService } from "../alert.service";
import { UserSettings } from "../../models/user-settings.model";
import { Observable, catchError } from "rxjs";

@Injectable()
export class RemoteServerServiceEndpoint extends EndpointBase {

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }

    private readonly settingsUrlPart: string = "/api/RemoteServer";

    get settingsUrl() { return this.configurations.baseUrl + this.settingsUrlPart; }

    getServersStatesEndpoint<T>(newData: boolean): Observable<T> {
        let endpointUrl = this.settingsUrl;
        if (newData) endpointUrl+="/true"
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getServersStatesEndpoint(newData));
            }));
    }
    updateServersStatesEndpoint<T>(settings: T): Observable<T> {
        let endpointUrl = this.settingsUrl;
        return this.http.post<T>(endpointUrl, JSON.stringify(settings), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.updateServersStatesEndpoint(settings));
            }));
    }
}

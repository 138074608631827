import { Component, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data, RouterModule } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { IconsModule } from '../../../../icon.module';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { CoreService } from '../../../../services/core.service';
import { NavItem } from '../../vertical/sidebar/nav-item/nav-item';
import { navAddItems, navItems } from '../../vertical/sidebar/sidebar-data';




@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [RouterModule, MatIconModule, IconsModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: [],
})
export class AppBreadcrumbComponent {


  // @Input() layout;
  pageInfo: Data | any = Object.create(null);

  options = this.settings.getOptions();

  constructor(
    private settings: CoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
    , private translationService: AppTranslationService
  ) {

    let items = navItems.concat(navAddItems);

  

    // for breadcrumb
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      //      .pipe(map(() => this.activatedRoute))
      .pipe(map(() => this))
/*
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
*/
//      .pipe(filter((route) => route.outlet === 'primary'))
      // .pipe(mergeMap((route) => route))
      // tslint:disable-next-line - Disables all
      .subscribe((route) => {
        // tslint:disable-next-line - Disables all
        
        
        let fullPath = router.url;
        var aroute = route.activatedRoute;
        while (aroute.firstChild) {
          aroute = aroute.firstChild;
         }

        aroute.data.subscribe((event) => {
          console.log(event);
          let mainMenu: NavItem;
          let menu: NavItem;
          for (let m of items) {
            if (m.route == fullPath) {
              menu = m;
              break;
            }
            if (m.children)
              for (let m2 of m.children) {
                if (m2.route == fullPath) {
                  mainMenu = m;
                  menu = m2;
                  break;
                }
                if (m2.children)
                  for (let m3 of m2.children) {
                    if (m3.route == fullPath) {
                      mainMenu = m2;
                      menu = m3;
                      break;
                    }
                  }


              }

          }
          var usePageTitle = false;
          var usePageMainTitle = false;

          // If trnsalte
          if(/\w\.\w/.test(event['mainTitle'])){
            event['mainTitle'] = this.translationService.getTranslation(event['mainTitle']);
            usePageMainTitle = true;
          }
          if(/\w\.\w/.test(event['title'])){
            event['title'] = this.translationService.getTranslation(event['title']);
            usePageTitle = true;
          }
          if (menu) {
            let icon = menu.iconName;
            if (!icon && mainMenu) icon = mainMenu.iconName;
            let title = 'mainMenu.' + menu.displayName;
            title = this.translationService.getTranslation(title);
            if (title.startsWith('mainMenu.')) title = event['title'];
            if (mainMenu) {
              let mainTitle = 'mainMenu.' + mainMenu.displayName;
              mainTitle = this.translationService.getTranslation(mainTitle);
              if (mainTitle.startsWith('mainMenu.')) title = event['mainTitle'];
              if (!usePageMainTitle)
                event['mainTitle'] = mainTitle;
            }

            if (!usePageTitle)
              event['title'] = title;
            event['icon'] = icon;
          }
          this.titleService.setTitle(event['title'] + ' ');
          this.pageInfo = event;


        });


      });
  }
}



export class UserAction {
    constructor(public type?: string,
        public time?: any

    ) {
    }
    public static ResetPassword = "ResetPassword";
    public static ResetLogin = "ResetLogin";
    public static TerminateUser = "TerminateUser";
    public static SuspendUser = "SuspendUser";
    public static ReactivateUser = "ReactivateUser";
}



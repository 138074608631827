<div class="loginPage loginModal">
  <div class="loginFormWrapper txtCenter">
    <div class="loginPage_top">
      <img src="../../assets/img/logo.png" alt="StochaC logo" class="loginPage_logo">
      <h1 class="loginPage_accroche" [innerHTML]="'login.title' | translate"></h1>
    </div>
    @if (!isSended) {
      <form class="loginForm js-loginForm" name="restForm" #f="ngForm" novalidate
        (ngSubmit)="f.form.valid ? save() :
        ( !modelEmail.valid && showErrorAlert('Email  is required', 'Please enter the correct email' ));">
        @if (user) {
          <h1 class="loginPage_accroche">Enter your email</h1>
        }
        <div class="loginForm_inputWrapper"
          [ngClass]="{'has-success': f.submitted && modelEmail.valid, 'has-error' : f.submitted && !modelEmail.valid}">
          <input type="email" id="newPassword" name="email"
            autocomplete="off"
            placeholder="Enter email" class="form-control"
            [(ngModel)]="email"
            #modelEmail="ngModel"
            required reverse="true" email />
            @if (f.submitted) {
              <span class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': modelEmail.valid, 'glyphicon-remove' : !modelEmail.valid}" aria-hidden="true"></span>
            }
            @if ( f.submitted && !modelEmail.valid) {
              <span class="errorMessage">
                Password is required
              </span>
            }
          </div>
          <button type="submit" class="s-btn br4 txt18 mtop40 s-btnOrange" [disabled]="isLoading">@if (isLoading) {
            <i class='fa fa-circle-o-notch fa-spin'></i>
          } {{isLoading ? 'Sending...' : 'Send'}}</button>
        </form>
      }
      @if (isSended) {
        <div>
          <br />
          <br />
          <br />
          <h5 class="loginPage_accroche">
            Rest password instruction was send to "<strong>{{email}}</strong>" email.
          </h5>
        </div>
      }
    </div>
  </div>
  
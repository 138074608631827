@if(item.navCap) {
<div mat-subheader class="nav-caption">
  {{ item.navCap }}
</div>
} @if(!item.navCap && !item.external && !item.twoLines) {
<mat-list-item (click)="onItemSelected(item)"
               class="f-w-600 menu-list-item"
               [ngClass]="{
    'mat-toolbar mat-accent activeMenu': item.route
      ? router.isActive(item.route, true)
      : false,
    expanded: item.expanded,
    disabled: item.disabled,
    'nav-caption': !depth
  }">
  <i-tabler name="{{ item.iconName }}"
            class="routeIcon"
            matListItemIcon></i-tabler>
  <span class="hide-menu"> {{ 'mainMenu.'+item.displayName | translate }}</span>
  @if(item.children && item.children.length) {
  <span class="arrow-icon" fxFlex>
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="item.expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
  } @if(item.chip) {
  <span>
    <span class="{{ item.chipClass }} p-x-8 p-y-4 item-chip f-w-500 rounded-pill ">{{ item.chipContent }}</span>
  </span>
  }
</mat-list-item>

  @if(item.route &&  router.isActive(item.route, true)){
<div class="menu-point"></div>
  }


}

<!-- external Link -->
@if(!item.navCap && item.external) {
<mat-list-item onClick="window.open('//google.com')"
               class="menu-list-item"
               target="_blank">
  <i-tabler class="routeIcon"
            name="{{ item.iconName }}"
            matListItemIcon></i-tabler>
  <span class="hide-menu">{{ item.displayName | translate }}</span>
</mat-list-item>
}

<!-- children -->
@if(item.expanded) {
<div>
  @for(child of item.children; track child) {
  <app-nav-item [item]="child"
                (click)="onSubItemSelected(child)"
                [depth]="depth + 1">
  </app-nav-item>
  }
</div>
}

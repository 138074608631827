import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { AppSettings } from '../../app.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AlertMessage, AlertService, MessageSeverity } from '../../services/alert.service';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';

@Component({
  selector: 'app-blank',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './blank.component.html',
  styleUrls: [],
})
export class BlankComponent implements OnInit {
  private htmlElement!: HTMLHtmlElement;

  options = this.settings.getOptions();
  stickyToasties: number[] = [];

  constructor(private settings: CoreService
    , private alertService: AlertService
    , private ToastaService: ToastrService

  ) {
    this.htmlElement = document.querySelector('html')!;
    // Initialize project theme with options
    this.receiveOptions(this.options);
  }
    ngOnInit(): void {
      this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));

    }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }

  showToast(message: AlertMessage, isSticky: boolean) {

    
    if (message == null) {
      for (let id of this.stickyToasties.slice(0)) {
        this.ToastaService.clear(id);
      }

      return;
    }

    let toastOptions = { timeOut: isSticky ? 0 : 4000 };

    let severity: string = 'info';
    let toasta: ActiveToast<any> ;
    switch (message.severity) {
      case MessageSeverity.default:
      case MessageSeverity.info:
        toasta = this.ToastaService.info(message.detail, message.summary, toastOptions);
        severity = 'info';
        break;
      case MessageSeverity.success:
        toasta =this.ToastaService.success(message.detail, message.summary, toastOptions);
        severity = 'success';
        break;
      case MessageSeverity.error:
        toasta = this.ToastaService.error(message.detail, message.summary, toastOptions);
        severity = 'error';
        break;
      case MessageSeverity.warn:
        toasta = this.ToastaService.warning(message.detail, message.summary, toastOptions);
        severity = 'warn';
        break;
      case MessageSeverity.wait:
        toasta = this.ToastaService.info(message.detail, message.summary, toastOptions);
        severity = 'info';
        break;
    }

    // let toasta = this.ToastaService.show(message.detail, message.summary, toastOptions, severity);

    if (isSticky) {
      toasta.onAction.pipe(take(1))
        .subscribe(() => this.stickyToasties.push(toasta.toastId));

      toasta.onHidden.pipe(take(1))
        .subscribe(() => {
          let index = this.stickyToasties.indexOf(toasta.toastId, 0);

          if (index > -1) {
            this.stickyToasties.splice(index, 1);
          }

        });
    }

  }




}

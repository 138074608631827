<ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title pull-left"><i class="fa fa-user-circle-o"></i> {{Messages}}</h4>
          <button type="button" class="close pull-right" title="{{ 'app.Close' | translate}}" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="popup_container container-fluid">
            <div class="row">
              <div class="col-xs-6 col-xs-offset-3 col-sm-offset-4 col-sm-4  col-md-2 col-md-offset-5">

                <div class="profilePic" [style.background-image]="iconBgImage">
                  <span class="profilPic_initials"></span>
                </div>
                <form class="updateProfilePic js-updateProfilePic">
                  <input type="file" id="profilePicUpload" (selectionChange)="onLogoChange($event)" class="fileInput">
                  <label for="profilePicUpload" class="profilePicUpload">+</label>
                </form>

              </div>
            </div>
            <form class="form-horizontal" name="form" #f="ngForm" novalidate
                  (ngSubmit)="f.form.valid ? update() : (!inTitle.valid && showErrorAlert('Chat Title name is required', 'Please enter a title for the chat'));;">
              <div class="row">
                <div class="PD_contractTypePopup_nameInputWrapper">
                  <label for="" class="PD_contractTypePopup_label">{{Title}}</label>
                  <input autofocus type="text" name="ttile" id="chatTitle"
                         [(ngModel)]="value"
                         #inTitle="ngModel"
                         class="js-PD_contractNameInput PD_contractTypePopup_contractInput textInput"
                         autocomplete="off" required autofocus>
                </div>
              </div>


            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-4 col-xs-offset-4">
              <button type="button" [disabled]="!f.form.valid" c (click)="update()" class="s-btn s-btnBlue br4">
                Update
              </button>
            </div>
          </div>

        </div>
</ng-template>

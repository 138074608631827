<!--  main containers -->
<div class="js-wrapper wrapper chatbot">
  <div class="container mainContainer">
    <div class="row">
      <div class="col col-xs-12">
        <div class="pageTitleWrapper">
          <i class="icon-users pageTitleIcon" aria-hidden="true"></i>
          <h1 class="pageTitle">{{'mainMenu.Support' | translate}} : <span class="bold">{{'mainMenu.ChatRoom' | translate}}</span> </h1>
          <div class="clearfix"></div>
        </div>
        <hr class="hr-black">
      </div>
    </div>
    <section class="row">
      <div class="col-md-3 CB_statuses">
        <div class="CB_statuses_actions">
          <!--
          <a href="#" class="s-btn s-btn--autoWidth s-btnBlue br4 CB_onlineUsers_logoff">Log Off</a>
          -->
          <div class="CB_statuses_statusSelect">
            <mat-select class="js-blueCustomSelect select-combo-blue" [(value)]="myStatus" place-holder="Online Status"
              (selectionChange)="setStatus()">
              <mat-option value="online">{{'chat.status.online' | translate}}</mat-option>
              <mat-option value="away">{{'chat.status.away' | translate}}</mat-option>
              <mat-option value="doNotDisturb">{{'chat.status.doNotDisturb' | translate}}</mat-option>
              <mat-option value="invisible">{{'chat.status.invisible' | translate}}</mat-option>
              <mat-option value="offline">{{'chat.status.offline' | translate}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="CB_statuses-onlineUsers">
          <div class="CB_onlineUsers_legend">
            <span class="txtPmpkin txt20">{{getUsersOnlineCount()}} </span>
            <span>{{'chat.OnlineUsers' | translate}}</span>
          </div>
          <div class="CB_onlineUsers_list">
            @for (chat of getActiveGroupChat(); track chat) {
              <div class="CB_onlineUsers_user"
                >
                <div class="CB_onlineUsers_mute CB_onlineUsers_mute--muted js-CB_muteUser"
                  [ngClass]="{'CB_onlineUsers_mute--muted':getMuteChat(chat.id)}"
                  (click)="muteChat(chat.id)"
                  title="{{'chat.Muted' | translate}}">
                  <i class="icon-voice CB_onlineUsers_muteIcon"></i>
                </div>
                <div class="userRoundAvatar CB_userAvatar CB_onlineUsers_userAvatar"
                  [style.background-image]="getIconBgImage(chat)">
                  @if (!chat.icon) {
                    <i class="fa fa-users"></i>
                  }
                </div>
                @if (chat.title) {
                  <span
                    [ngClass]="{'active-chat':(activeChat?.id==chat.id && activeChat?.isGroup)}"
                    (click)="openChat(chat)"
                  class="chat-title">{{chat?.title}}</span>
                }
                @if (!chat.title) {
                  <span class="CB_onlineUsers_username chat-title"
                    [ngClass]="{'active-chat':(activeChat?.id==chat.id && activeChat?.isGroup)}"
                    (click)="openChat(chat)">
                    @for (user of getChatUsers(chat); track user; let first = $first) {
                      <span>
                        @if (!first) {
                          <span>, </span>
                        }
                        {{user.name}}
                      </span>
                    }
                  </span>
                }
                <span class="CB_onlineUsers_userStatus">
                  <button class="CB_onlineUsers_delete fa fa-trash" (click)="deleteGroupChat(chat)"></button>
                </span>
              </div>
            }
            @for (user of getUsersAndChat(); track user) {
              <div class="CB_onlineUsers_user"
                >
                <div class="CB_onlineUsers_mute CB_onlineUsers_mute--muted js-CB_muteUser"
                  [ngClass]="{'CB_onlineUsers_mute--muted':getMuteChat(null,user.id)}"
                  (click)="muteChat(null,user.id)"
                  title="{{'chat.Muted' | translate}}">
                  <i class="icon-voice CB_onlineUsers_muteIcon"></i>
                </div>
                <div class="userRoundAvatar CB_userAvatar CB_onlineUsers_userAvatar"
                [style.background-image]="getBgImage(user.pictureFileName)"></div>
                <span class="CB_onlineUsers_username" [ngClass]="{'active-chat':(activeUser.id==user.id && !activeChat?.isGroup)}" (click)="openUserChat(user)">{{user.firstName}} {{user.lastName}}</span>
                <span class="CB_onlineUsers_userStatus CB_onlineUsers_userStatus--{{user.status}}"></span>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="col-md-9" [style.display]="(activeUser.id || activeChat?.id) ? 'block' : 'none'">
        <div class="CB_chatlog">
          <div class="CB_chatlog_header row">
            <div class="CB_chatlog_title col-md-8">
              @if (activeChat && activeChat.isGroup ) {
                <span class="chat-title">
                  {{getChatTitle(activeChat)}}
                </span>
              }
              @if (activeChat && activeChat.isGroup) {
                <button infoBubble type="button" class="btn btn-xs btn-default btn-chat-icon" (click)="editTitle(activeChat)"><span class="fa fa-users"></span></button>
              }
              @if (activeChat && activeChat.isGroup) {
                <div class="infoBubble js-infoBubble infoBubble--arrowLeft">
                  {{'chat.CreateGroup' | translate}}
                </div>
              }
            </div>
            <div class="CB_chatlog_title_users col-md-4 text-right">
              <button type="button" infoBubble class="btn btn-xs btn-default chat_join_users_btn" (click)="addUserWin(activeChat)"><span class="glyphicon glyphicon-plus"></span></button>
              <div class="infoBubble js-infoBubble infoBubble--arrowRight">
                {{'chat.AddRemoveUsers' | translate}}
              </div>
              @for (user of getChatUsers(activeChat); track user; let first = $first) {
                <span>
                  @if (!first) {
                    <span>, </span>
                  }
                  {{user.name}}
                </span>
              }
            </div>
          </div>
          <div SimpleBar #simpleBar="simple-bar" class="CB_chatlog_messagesBox customScrollbar clearfix">
            @for (message of messages; track message) {
              <div class="CB_chatlog_message">
                <div class="CB_chatlog_messageSender">
                  <div class="CB_userInitials CB_chatlog_userInitials" [style.background-image]="message.fromUser.picture">{{message.fromUser.initials}}</div>
                </div>
                <div class="CB_chatlog_messageContent CB_chatlog_messageContent--chat">
                  <p class="CB_chatlog_messageTime">{{message.time | date:'short'}}</p>
                  <p class="CB_chatlog_messageText">
                    <span class="CB_chatlog_messageAuthorName">
                      {{message.fromUser.name}}:
                    </span>
                    @if (message.deleted) {
                      <span class="CB_chatlog_messageTxtDelete">
                        {{'app.Deleted' | translate}}
                      </span>
                    }
                    @if (!message.deleted) {
                      <span class="CB_chatlog_messageTxt">
                        {{message.text}}
                      </span>
                    }
                    @if (message.info && !message.deleted) {
                      <a href="#" class="CB_chatlog_messageFile" (click)="loadMessageFile(message.info)">
                        <i class="icon-docx-file CB_chatlog_messageFileFormat"></i> {{message.info.fileName}} - {{message.info.size | fileSize:2 }}
                      </a>
                    }
                    @if (!message.deleted) {
                      <a href="#" class="CB_chatlog_delete" (click)="deleteMessage(message)">
                        <i class="fa fa-trash"></i>
                      </a>
                    }
                  </p>
                </div>
              </div>
            }
            <div class="chat-typing" [hidden]="!typingStatus">
              @if (typingStatus) {
                <div>
                  <span class="chat-typing-user">{{typingStatus.fromUser.name}}</span>
                  <span class="chat-typing-info">{{'chat.typing' | translate}}</span>
                </div>
              }
            </div>
          </div>
        </div>
        <form action="" method="post" class="CB_messageBox">
          <div class="CB_messageBox_textareaWrapper">
            <textarea class="basicTextarea" placeholder="Type your message here" name="message"
              (ngModelChange)="onMessageTyping($event)"
              (keyup.enter)="sendMessage()"
            [(ngModel)]="message"></textarea>
          </div>
          <div class="CB_messageBox_fileInputWrapper" #addFile>
            <input type="file" name="file" class="addFileInput CB_messageBox_fileInput">
            <div for="addFile" class="CB_messageBox_label">
              <!--  there are three states for this button :
              - when no file is loaded
              - when a file is loading
              - when a file is loaded
              display the right div depending on state of button
              -->
              <div class="CB_messageBox_btn addFileBtn" (click)="uploadFile()">
                <span class="CB_messageBox_btnIcon">+</span>
              </div>
              <div class="CB_messageBox_loadingFile addFileLoading">
                <img src="../../assets/img/loader.svg" alt="" class="loader">
              </div>
              <div class="CB_messageBox_fileLoaded addFileInfo">
                <i class="icon-csv-file CB_messageBox_fileLoadedFormat"></i>
                <span class="CB_messageBox_fileLoadedName">{{messageFile.fileName}} - {{messageFile.size | fileSize:2 }}</span>
              </div>
            </div>
          </div>
          <button type="submit" name="button" class="s-btn s-btnOrange br4 CB_messageBox_sendBtn" (click)="sendMessage()">{{'app.Send' | translate}}</button>
        </form>
      </div>
    </section>
    <scroll-top></scroll-top>
  </div> <!-- /container -->
</div> <!-- /wrapper -->
<update-users #updateUsersChat [myId]="myId" [users]="users" (endUpdate)="updateUserChat($event)"></update-users>
<update-title #updatetitle (endUpdate)="endEditTitle($event)" ></update-title>

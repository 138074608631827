import { Injectable } from '@angular/core';
import { GroupPermissionsEndpoint } from './group-permissions-endpoint.service';
import { DeploymentPermissions } from '../../models/Security/deployment-permissions.model';

@Injectable()
export class GroupPermissionsService {
    constructor(private groupPermissionsEndpoint: GroupPermissionsEndpoint) {
    }
    saveGroups(groupId: number, groupPermissions: DeploymentPermissions[]) {
        return this.groupPermissionsEndpoint.saveGroupsEndpoint<DeploymentPermissions[]>(groupId, groupPermissions);
    }
    saveUserCompany(userId: string, companyId: number, groupPermissions: DeploymentPermissions[]) {
        return this.groupPermissionsEndpoint.saveUserCompanyEndpoint<DeploymentPermissions[]>(userId, companyId, groupPermissions);
    }
    getGroupPermissions(groupId: number) {
        return this.groupPermissionsEndpoint.getGroupPermissionsEndpoint<DeploymentPermissions[]>(groupId);
}
    getListPermissions() {
        return this.groupPermissionsEndpoint.getListPermissions<DeploymentPermissions[]>();
    }
    getUserUserExceptionsPermissions(userId?: string, companyId?: number) {
        return this.groupPermissionsEndpoint.getUserExceptionsEndpoint<DeploymentPermissions[]>(userId, companyId);
    }
    
}

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AlertService } from '../alert.service';
import { TemplateFileModel } from '../../models/files-templates/template-file.model';

@Injectable()
export class FileTemplateEndpoint extends EndpointBase {

    private readonly urlPart: string = "/api/FileTemplate";

    get url() { return this.configurations.baseUrl + this.urlPart; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }


    getFilesEndpoint<T>(): Observable<T> {
        let endpointUrl = this.url;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getFilesEndpoint());
            }));
    }
    getFileEndpoint<T>(id): Observable<T> {
        let endpointUrl = `${this.url}/${id}`;
        let options = <any>this.getRequestHeaders()
        options.responseType = "blob";
        return this.http.get<T>(endpointUrl, options)
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getFileEndpoint(id));
            }));
    }
    getDeleteFilesEndpoint<T>(ids:string[]): Observable<T> {
        let endpointUrl = `${this.url}/` + ids.join(",");
        return this.http.delete<T>(endpointUrl,  this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getDeleteFilesEndpoint(ids));
            }));
    }
    getUpdatInfoEndpoint<T>(id: string, info: TemplateFileModel): Observable<T> {
        let endpointUrl = `${this.url}/info/` + id;
        return this.http.put<T>(endpointUrl, { module: info.module, typeInfo: info.typeInfo }, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdatInfoEndpoint(id, info));
            }));
    }
}

import { CppInputType } from "./cpp/cpp-structure.model";

export class ServersStates {
    public static readonly MaxGpu = "maxgpu";
    public static readonly Gpu = "gpu";
    public static readonly Cpu = "cpu";
    public static readonly Python = "python";
    public static readonly R = "r";
    public static readonly Exec = "exec";
    public static readonly Local = "local";
    constructor(public isOn?: { [label: string]: boolean },
        public inProcess?: { [label: string]: boolean },
        public isAllow?: { [label: string]: boolean }
    ) {

    }
    static getList() {
        var item: CppInputType[] = [];
        item.push({ type: ServersStates.Local, name: "Local" });
        item.push({ type: ServersStates.Cpu, name: "CPUServer" });
        item.push({ type: ServersStates.Gpu, name: "GPUServer" });
        item.push({ type: ServersStates.MaxGpu, name: "MaxGPUServer" });
        item.push({ type: ServersStates.R, name: "RServer" });
        item.push({ type: ServersStates.Python, name: "PythonServer" });
        return item;
    }
}
export class ServersStatusAction {
    constructor(public isOn: boolean, public type: string) {

    }
}

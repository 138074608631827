import { Routes } from '@angular/router';


import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";

import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChatBotComponent } from './components/chatbot/chatbot.component';
import { Permission } from './models/permission.model';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginMainComponent } from './components/login/login-main.component';



export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [

      { path: "", component: HomeComponent, data: { title: "Home" } },
      {
        path: 'calc',
        loadChildren: () => import('./components/calculation/calculation.module').then(m => m.CalculationModule)
      },




      /*
      {
          path: 'reserves',
          loadChildren: './components/reserves/reserves.module#ReservesModule'
      },
      */




      /*
      { path: "customers", component: CustomersComponent, canActivate: [AuthGuard], data: { title: "Customers", permission: Permission.UserManagementPermission } },
      { path: "products", component: ProductsComponent, canActivate: [AuthGuard], data: { title: "Products", permission: Permission.ProductDefinitionsPermission } },
      */
      /*
      { path: "orders", component: OrdersComponent, canActivate: [AuthGuard], data: { title: "Orders" } },
      { path: "settings", component: SettingsComponent, canActivate: [AuthGuard], data: { title: "Settings" } },
      { path: "about", component: AboutComponent, data: { title: "About Us" } },
      */

      { path: "home", redirectTo: "/", pathMatch: "full" },
      {
        path: 'administration',
        loadChildren: () => import('./components/administration/administration.module').then(m => m.AdministrationModule)
      },
      {
        path: 'audit',
        loadChildren: () => import('./components/audit/audit.module').then(m => m.AuditModule)
      },
      /*
      { path: "audit-trail", component: AuditTrailComponent, canActivate: [AuthGuard], data: { title: "Audit Trail & Query Reporting", permission: Permission.AuditTrialPermission } },
      { path: "audit-auditor", component: AuditAuditorComponent, canActivate: [AuthGuard], data: { title: "Stochac Auditor", permission: Permission.AuditTrialPermission } },
      { path: "audit-auditor/validate", component: AuditAuditorValidateComponent, canActivate: [AuthGuard], data: { title: "Stochac Auditor Validate", permission: Permission.AuditTrialPermission } },
      { path: "audit-chart", component: ChartListComponent, canActivate: [AuthGuard], data: { title: "Reports & Analytics", permission: Permission.BiNAnalyticsPermission } },
      { path: "audit-chart/builder/:id", component: ChartBuilderComponent, canActivate: [AuthGuard], data: { title: "Reports & Analytics", permission: Permission.BiNAnalyticsPermission } },
      { path: "audit-chart/builder/:id/:chartid", component: ChartBuilderComponent, canActivate: [AuthGuard], data: { title: "Reports & Analytics", permission: Permission.BiNAnalyticsPermission } },
      { path: "audit-chart/:id", component: ChartListComponent, canActivate: [AuthGuard], data: { title: "Reports & Analytics", permission: Permission.BiNAnalyticsPermission } },
      */

      { path: "chatbot", component: ChatBotComponent, canActivate: [AuthGuard], data: { title: "Chat Room", permission: Permission.ChatRoomPermission } },
      { path: "chatbot/:id", component: ChatBotComponent, canActivate: [AuthGuard], data: { title: "Chat Room", permission: Permission.ChatRoomPermission } },


      /*
      { path: "r-alm", component: RAlmComponent, canActivate: [AuthGuard], data: { title: "R Asset Liability Management : R-ALM", permission: Permission.AssetLiabilityModelingPermission } },
      { path: "alm", component: AlmComponent, canActivate: [AuthGuard], data: { title: "Asset Liability Management : ALM", permission: Permission.AssetLiabilityModelingPermission } },
      */






      {
        path: 'assets',
        loadChildren: () => import('./components/liabilityassets/liabilityassets.module').then(m => m.LiabilityAssetsModule)
      },





      {
        path: 'etl-tool',
        loadChildren: () => import('./components/etl-tool/etl-tool.module').then(m => m.EtlToolModule)
      },

      {
        path: 'settings',
        loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule)
      },

      {
        path: 'support',
        loadChildren: () => import('./components/support/support.module').then(m => m.SupportModule)
      },

      {
        path: 'reporting',
        loadChildren: () => import('./components/reporting/reporting.module').then(m => m.ReportingModule)
      },

      /*
      { path: "bi-analytics", component: BiAnalyticsComponent, canActivate: [AuthGuard], data: { title: "Reports & Analytics", permission: Permission.BiNAnalyticsPermission } },
      */
      /*
      { path: "ai-bot", component: AiBotComponent, canActivate: [AuthGuard], data: { title: "A.I. Bot", permission: Permission.BiNAnalyticsPermission } },
      */
     ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: "login", component: LoginMainComponent, data: { title: "Login" } },
      { path: "reset-password", component: ResetPasswordComponent, data: { title: "Rest Password" } },
      { path: "forgot-password", component: ForgotPasswordComponent, data: { title: "Forgot password" } },
/*
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
*/
    ],
  },
  { path: "**", component: NotFoundComponent, data: { title: "Page Not Found" } }


]



import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreService } from '../../../../services/core.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [RouterModule],
  template: `
    <div class="branding d-none d-lg-flex align-items-center">
      <a [routerLink]="['/']" class="d-flex">
        <img
          src="./assets/img/v2/logo_light_v2.svg"
          class="logo-lg align-middle m-2"
          alt="logo"
        />
        <img
          src="./assets/img/v2/logo_light_sm_v2.svg"
          class="logo-sm align-middle m-2"
          alt="logo"
        />
      </a>
    </div>
  `,
})
export class BrandingComponent {
  options = this.settings.getOptions();

  constructor(private settings: CoreService) {}
}

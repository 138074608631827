
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { AccountService } from '../../../services/account.service';
import { SelectLabel, SelectOption } from '../../../models/select-label';
import { BatchProcess } from '../../../models/batch/batch-job.model';
import { AccessTypeEnum, PermissionValues } from '../../../models/permission.model';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { IconsModule } from '../../../icon.module';
import { NavItem } from '../../../layouts/full/vertical/sidebar/nav-item/nav-item';
import { navAddItems, navItems } from '../../../layouts/full/vertical/sidebar/sidebar-data';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppTranslationService } from '../../../services/app-translation.service';




@Component({
  selector: 'home-box',
  templateUrl: './home-box.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule,
    MatTooltipModule,
    MatInputModule, MatButtonModule,
    MatCardModule, MatMenuModule, MatIconModule, IconsModule]
})
export class HomeBoxComponent implements OnInit, AfterViewInit {

  public item: NavItem = null;
  @Input()
  public displayName: string = '';
  @Input()
  public displayTitle: string = '';
  @Input()
  public displayToolTip: string = '';
  @Input()
  public iconName: string = '';
  
  @Input()
  public bgBox: string = '';
  @Input()
  public bgBoxHeader: string = '';


  @Input()
  public image: string = '';


  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private accountService: AccountService, private translationService: AppTranslationService, public router: Router) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    let items = navItems.concat(navAddItems);
    
      var f = items.find(n => n.displayName == this.displayName);
      if (f) {
        if (this.canView(f.permission, f.accessType))
          this.item = f;
        if (!this.displayTitle)
          this.displayTitle = this.item.displayTitle;
        if (!this.displayToolTip)
          this.displayToolTip = this.item.displayToolTip;
        if (!this.iconName)
          this.iconName = this.item.iconName;

        
      }

  }

  public canView(value: string | string[], accessType?: number): boolean {
    if (!value) return true;
    if (typeof  value == 'string') {
      return this.accountService.userHasPermission(<PermissionValues>value, <AccessTypeEnum>accessType)
    }else {
      return this.canViewAny(value)
    }
  }
  public canViewAny(values: string[]): boolean {
    var isAllow = false;
    for (var value of values) {
      if (this.accountService.userHasPermission(<PermissionValues>value)) return true;
    }
    return false;
  }

  onItemSelected(item: NavItem) {
    this.router.navigate([item.route], { replaceUrl: true });
    //scroll
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
      if (window.innerWidth < 1024) {
        this.notify.emit();
      }
  }
  getTransalteMenu(name: string): string {
    if (!name || name.indexOf(" ") > 0)
        return name;
    return this.translationService.getTranslation('mainMenu.' + name);
  }
}

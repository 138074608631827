import { ServersStates, ServersStatusAction } from "../../models/server-states.model";
import { RemoteServerServiceEndpoint } from "./remote-server-endpoint.service.ts";
import { Injectable } from "@angular/core";

@Injectable()
export class RemoteServerService {
    constructor(private remoteServerServiceEndpoint: RemoteServerServiceEndpoint) {

    }

    getServersStates(newData: boolean) {
        return this.remoteServerServiceEndpoint.getServersStatesEndpoint<ServersStates>(newData);
    }
    updateServersStates(settings: ServersStatusAction) {
        return this.remoteServerServiceEndpoint.updateServersStatesEndpoint<ServersStatusAction>(settings);
    }
}

import { AppUser } from "../Security/app-user.model";
import { Message, TypingStatus } from "./Message.model";
import { UploadFile } from "../upload-file.model";


export class ChatUpdateInfo {
    public oldId?: string;
    public newId?: string;
}
export class ChatInfo {
    public id?: string;
    public isGroup: boolean;
    public toUserId: string;
    public title?: string;
    public users: AppUser[];
    public info?: string;
    public messages: Message[];
    public lastActive: Date;
    public endDate: Date;
    public startDate: Date;
    public IsNotification: boolean=true;
    public icon?: string;
}
export class ChatWinodw {
    public isOpen: boolean;
    public message: string;
    public messageFile: UploadFile;
    public typingTimeout: number;
    public typingInTimeout: number;
    public typingStatus: TypingStatus;

    constructor(public info: ChatInfo) {
        this.isOpen = true;
        this.message = "";
        this.messageFile = { fileName: '', size: 0 };
        
    }
}

export class ChatUserSetting {
    constructor(public chatId: string, public userId: string, public disabled: boolean) {
    }
}



import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavService } from '../../../../services/nav.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from '../../../../material.module';
import { RouterModule } from '@angular/router';
import { AppNavItemComponent } from './nav-item/nav-item.component';
import { AuthService } from '../../../../services/auth.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import { NotificationService } from '../../../../services/notification.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';


@Component({
  selector: 'app-sidebar', 
  standalone: true,
  imports: [
    NgScrollbarModule,
    TablerIconsModule,
    MaterialModule,
    RouterModule,
    AppNavItemComponent, CommonModule
  ],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  navopt = this.navService.showClass;
  @Input() userName: string;
  @Input() userBgImage: string;
  @Output() onLogout = new EventEmitter<void>();

  // @ViewChild('popNote') popNote: PopoverDirective;



  constructor(
    public navService: NavService
    , private authService: AuthService
    , public configurations: ConfigurationService
    , private notificationService: NotificationService
    , private translationService: AppTranslationService
    , private alertService: AlertService
  ) { }


  logout() {
    console.log(`logout  SidebarComponent`);

    this.onLogout.emit();
  }


  

}


import { CommonModule } from "@angular/common";
import { Component} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { LoginComponent } from "./login.component";

@Component({
  selector: "login-main",
  templateUrl: './login-main.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, LoginComponent]


})


export class LoginMainComponent { }

import { Injectable, Injector } from "@angular/core";
import { EndpointBase } from "../endpoint-base.service";
import { HttpClient } from "@angular/common/http";
import { ConfigurationService } from "../configuration.service";
import { AlertService } from "../alert.service";
import { UserSettings } from "../../models/user-settings.model";
import { Observable, catchError } from "rxjs";

@Injectable()
export class UserSettingsEndpoint extends EndpointBase {

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }

    private readonly settingsUrlPart: string = "/api/UserSettings";
  private readonly testUrlPart: string = "/api/FullTests";

    get settingsUrl() { return this.configurations.baseUrl + this.settingsUrlPart; }

    getSettingsEndpoint<T>(): Observable<T> {
      let endpointUrl = this.settingsUrl;
      return this.http.get<T>(endpointUrl, this.getRequestHeaders())
        .pipe(catchError(error => {
                return this.handleError(error, () => this.getSettingsEndpoint());
            }));
    }
    getUpdateUserSettingsEndpoint<T>(settings: T): Observable<T> {
        let endpointUrl = this.settingsUrl;
        return this.http.post<T>(endpointUrl, JSON.stringify(settings), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdateUserSettingsEndpoint(settings));
            }));
  }
  getFullTestsEndpoint<T>(): Observable<T> {
    let endpointUrl = this.testUrlPart;
    return this.http.get<T>(endpointUrl,  this.getRequestHeaders())
      .pipe(catchError(error => {
        return this.handleError(error, () => this.getFullTestsEndpoint());
      }));
  }
  startTestEndpoint<T>(test: T): Observable<T> {
    let endpointUrl = this.testUrlPart;
    return this.http.post<T>(endpointUrl, JSON.stringify(test), this.getRequestHeaders())
      .pipe(catchError(error => {
        return this.handleError(error, () => this.startTestEndpoint(test));
      }));
  }
  startApiEndpoint<T>(server: string, action:string): Observable<T> {
    let endpointUrl = this.testUrlPart + "/api/?server=" + server + "&action=" + action;
    return this.http.get<T>(endpointUrl,  this.getRequestHeaders())
      .pipe(catchError(error => {
        return this.handleError(error, () => this.startApiEndpoint(server, action));
      }));
  }
}

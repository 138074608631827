@if(data.title){
<h2 mat-dialog-title>{{data.title}}</h2>
}
<mat-dialog-content>
  <p>{{data.message}}</p>

  @if(data.type==DialogType.alert){
  }@else if(data.type==DialogType.confirm){
  }@else if(data.type==DialogType.prompt){
  <mat-form-field>
    @if(data.valueTitle){
    <mat-label>{{data.valueTitle}}</mat-label>
    }
    <input matInput [(ngModel)]="data.defaultValue">
  </mat-form-field>
  }


</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  @if(data.type==DialogType.alert){
  <button mat-flat-button  [mat-dialog-close] color="primary" cdkFocusInitial>{{data.okLabel}}</button>
  }@else if(data.type==DialogType.confirm){
  <button mat-stroked-button [mat-dialog-close]="false">{{data.cancelLabel}}</button>
  <button mat-flat-button  [mat-dialog-close]="true" cdkFocusInitial color="primary">{{data.okLabel}}</button>
  }@else if(data.type==DialogType.prompt){
  <button mat-stroked-button [mat-dialog-close]="null">{{data.cancelLabel}}</button>
  <button mat-flat-button  [mat-dialog-close]="data.defaultValue" cdkFocusInitial color="primary">{{data.okLabel}}</button>
  }
</mat-dialog-actions>

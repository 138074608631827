import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';

import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AlertService } from '../alert.service';

@Injectable()
export class CompanyEndpoint extends EndpointBase {

    private readonly companyUrlPart: string = "/api/Companies";

    get companyUrl() { return this.configurations.baseUrl + this.companyUrlPart; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }

    getUserCompaniesEndpoint<T>(): Observable<T> {
        let endpointUrl = this.companyUrl+'/me';
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUserCompaniesEndpoint());
            }));
    }
    getSiteCompaniesEndpoint<T>(): Observable<T> {
        let endpointUrl = this.companyUrl;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getSiteCompaniesEndpoint());
            }));
    }

    getCompanyEndpoint<T>(id: number): Observable<T> {
        let endpointUrl = `${this.companyUrl}/${id}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getCompanyEndpoint(id));
            }));
    }

    getUpdateCompanyEndpoint<T>(companyObject: any, companyId?: number): Observable<T> {
        let endpointUrl = `${this.companyUrl}/${companyId}`;

        return this.http.put<T>(endpointUrl, JSON.stringify(companyObject), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdateCompanyEndpoint(companyObject, companyId));
            }));
    }

    getAddCompanyEndpoint<T>(companyObject: any): Observable<T> {
        let endpointUrl = `${this.companyUrl}`;

        return this.http.post<T>(endpointUrl, JSON.stringify(companyObject), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getAddCompanyEndpoint(companyObject));
            }));
    }
    getDeleteCompanyEndpoint<T>(id: number): Observable<T> {
        let endpointUrl = `${this.companyUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getDeleteCompanyEndpoint(id));
            }));
    }
}

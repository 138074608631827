<mat-toolbar class="topbar">
  <app-branding></app-branding>

  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  @if(showToggle) {
  <button mat-icon-button
          (click)="toggleCollapsed.emit()"
          class="d-flex justify-content-center">
    <i-tabler name="menu-deep" class="icon-20 d-flex menu-header"></i-tabler>
  </button>
  }

  <!-- Mobile Menu -->
  @if(!showToggle) {
  <button mat-icon-button
          (click)="toggleMobileNav.emit()"
          class="d-flex justify-content-center">
    <i-tabler name="menu-deep" class="icon-20 d-flex menu-header"></i-tabler>
  </button>
  }


  <div class="m-10 " style="min-width: 140px">
    <mat-button-toggle-group [hideSingleSelectionIndicator]="false" class="m-r-20" aria-label="Deployment">
      <mat-button-toggle value="P" [checked]="deploymentCode=='P'" (click)="setupDeployment('P')" [disabled]="!isAllowDeployment('P')">P</mat-button-toggle>
      <mat-button-toggle value="T" [checked]="deploymentCode=='T'" (click)="setupDeployment('T')" [disabled]="!isAllowDeployment('T')">T</mat-button-toggle>
      <mat-button-toggle value="D" [checked]="deploymentCode=='D'" (click)="setupDeployment('D')" [disabled]="!isAllowDeployment('D')">P</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="m-10 w-100" style="max-width: 550px">

    <mat-form-field appearance="outline"
                    color="secondary"
                    size="small"
                    class="hide-hint  search w-100">
      <mat-icon matPreffix>search</mat-icon>
      <input matInput placeholder="Search here" />
    </mat-form-field>
  </div>

  <!-- --------------------------------------------------------------- -->
  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <!--
  <button mat-icon-button
          (click)="openDialog()"
          class="d-flex justify-content-center">
    <i-tabler name="search" class="icon-20 d-flex"></i-tabler>
  </button>
    -->
  <span class="flex-1-auto"></span>






  <div class="m-10 w-100" style="max-width: 550px">
    <mat-form-field appearance="outline"
                    color="secondary"
                    size="small"
                    class="hide-hint  company-profile w-100">

      <mat-select name="company" class="select-combo-header header-company" id="company-drop" [(ngModel)]="companyId"
                  (selectionChange)="changeCpompany()">
        @for (company of companies; track company) {
        <mat-option [value]="company.id">
          {{
          company.name
          }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <button mat-icon-button
          (click)="toggleMobileNav.emit()"
          class="d-flex justify-content-center">
    <i-tabler name="sitemap" class="icon-25 d-flex menu-header"></i-tabler>
  </button>


  <button mat-icon-button
          (click)="toggleMobileNav.emit()"
          class="d-flex justify-content-center">
    <i-tabler name="settings" class="icon-25 d-flex menu-header"></i-tabler>
  </button>

  <!-- --------------------------------------------------------------- -->
  <!-- langugage Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!--
    <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
      <img [src]="selectedLanguage.icon" class="rounded object-cover icon-25" />
    </button>
    <mat-menu #flags="matMenu" class="cardWithShadow">
      @for(lang of languages; track lang.language) {
      <button mat-menu-item (click)="changeLanguage(lang)">
        <div class="d-flex align-items-center">
          <img [src]="lang.icon" class="rounded object-cover m-r-8 icon-20" />
          <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
        </div>
      </button>
      }
    </mat-menu>

  -->
  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button
          [matMenuTriggerFor]="notificationmenu"
          aria-label="Notifications">
    <mat-icon [matBadge]="pinnedNotificationCount>0?pinnedNotificationCount:null"
              matBadgeOverlap="false"
              matBadgeColor="warn"
              class="header-badge icon-25">notifications</mat-icon>
  </button>
  <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Notifications</h6>
      <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">{{newNotificationCount}} new</span>
      </span>
    </div>


    @for(notification of rows; track notification.title) {
    <div mat-menu-item class="p-x-32 p-y-16">
      <div class="d-flex align-items-center">
        <button mat-icon-button
                (click)="deleteNotification(notification)">
          <mat-icon>x</mat-icon>
        </button>

        <button mat-icon-button
                (click)="togglePin(notification)">
          @if (notification.isPinned) {
          <mat-icon class="rounded-circle icon-35">pinned</mat-icon>
          }
          @if (!notification.isPinned) {
          <mat-icon class="rounded-circle icon-35 rotate-90">pinned</mat-icon>
          }
        </button>
        <a (click)="openInfo(row)">
          <div class="m-l-16">
            <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1" [ngClass]="{'f-w-600':!notification.isRead}">
              {{ notification.header }}
            </h5>
            <span [class.unread]="!notification.isRead" attr.title="{{notification.body}}">{{ notification.title }}</span>
          </div>
        </a>
      </div>
    </div>
    }

    <div class="p-y-12 p-x-32">
      <button mat-stroked-button color="primary" class="w-100">
        See all notifications
      </button>
    </div>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- Messages Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button [matMenuTriggerFor]="msgmenu" aria-label="Messages">
    <mat-icon matBadge="2"
              matBadgeOverlap="false"
              matBadgeColor="warn"
              class="header-badge">inbox  </mat-icon>
  </button>
  <mat-menu #msgmenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">Messages</h6>
      <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">5 new</span>
      </span>
    </div>

    @for(msg of msgs; track msg.title) {
    <button mat-menu-item class="p-x-32 p-y-16">
      <div class="d-flex align-items-center">
        <img [src]="msg.img" class="rounded-circle" width="48" />
        <div class="m-l-16">
          <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
            {{ msg.title }}
          </h5>
          <span>{{ msg.subtitle }}</span>
        </div>
      </div>
    </button>
    }

    <div class="p-y-12 p-x-32">
      <button mat-stroked-button color="primary" class="w-100">
        See all messages
      </button>
    </div>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button mat-icon-button
          [matMenuTriggerFor]="profilemenu"
          aria-label="Notifications">
    <img [src]="userBgImage"
         class="rounded-circle object-cover icon-35 profile-dd"
         width="35" />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd profile-menu cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 310px;">

        <div class="row border">

          <div class="col-8 ">
            <div class="row text-center">
              <div class="col-6 h-33 border">
                  <a mat-button class="w-100 box-sm">
                  <i-tabler name="briefcase" class="icon-20"></i-tabler>

                  <div>Toolbox</div>
                  </a>
</div>
              <div class="col-6 h-33 border">
                <a mat-button class="w-100  box-sm">
                  <i-tabler name="database" class="icon-20"></i-tabler>
                  <div>
                    Reserving
                  </div>
                  </a>
              </div>

              <div class="col-6 h-33 border">
                <a mat-button class="w-100  box-sm">
                  <i-tabler name="settings" class="icon-20"></i-tabler>
                  <div>
                    Modeling
                  </div>
                  </a>
              </div>
              <div class="col-6 h-33 border">
                <a mat-button class="w-100 box-sm">
                  <i-tabler name="cube" class="icon-20"></i-tabler>
                  <div>
                    ALM & Pricing
                  </div>
                  </a>
              </div>

              <div class="col-6 h-33 border">
                <a mat-button class="w-100 box-sm">
                  <i-tabler name="dashboard" class="icon-20"></i-tabler>
                  <div>
                    Regulary reporting
                  </div>
                  </a>
              </div>
              <div class="col-6 h-33 border">
                <a mat-button class="w-100 box-sm">
                  <i-tabler name="user-cog" class="icon-20"></i-tabler>
                  <div>
                    Account
                  </div>
                  </a>
              </div>
            </div>

          </div>
          <div class="col-4">

            <button mat-button class="w-100 p-t-20">
              <i-tabler name="user" class="icon-20"></i-tabler>
              
              

              My Profile
            </button>
            <button mat-button class="w-100 p-t-20" (click)="logout()"><i-tabler name="power" class="icon-20"></i-tabler>  Log off</button>

          </div>

        </div>

      

    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AlertService } from '../alert.service';

@Injectable()
export class SecurityEndpoint extends EndpointBase {
    private readonly groupUrlPart: string = "/api/UserGroup";

    get groupUrl() { return this.configurations.baseUrl + this.groupUrlPart; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }

    getGroupsEndpoint<T>(): any {
        let endpointUrl = this.groupUrl;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getGroupsEndpoint());
            }));
    }
    getGroupEndpoint<T>(id:number): any {
        let endpointUrl = this.groupUrl+`/${id}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getGroupEndpoint(id));
            }));
    }
    saveGroupsEndpoint<T>(groups: any): any {
        let endpointUrl = this.groupUrl+'/all';
        return this.http.put<T>(endpointUrl, JSON.stringify(groups), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.saveGroupsEndpoint(groups));
            }));
    }
    deleteGroupEndpoint<T>(id: number): any {
        let endpointUrl = this.groupUrl + `/${id}`;
        return this.http.delete<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.deleteGroupEndpoint(id));
            }));
    }
    addGroupEndpoint<T>(group: T): any {
        let endpointUrl = this.groupUrl;
        return this.http.post<T>(endpointUrl, JSON.stringify(group), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.addGroupEndpoint(group));
            }));
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BatchJob, ResultFile, BatchProcessResult } from '../../models/batch/batch-job.model';
import * as moment from 'moment';
import { EtlEndpoint } from './etl-endpoint.service';
import { OptionsModel } from '../../models/options.model';
import { ReadSetting } from '../../models/etl/read-setting.model';
import { PreviewCsv } from '../../models/etl/preview-csv.model';
import { FilePattern } from '../../models/etl/file-pattern.model';
import { MappingStructure } from '../../models/etl/maping-structure.model';
import { MappingModel } from '../../models/etl/mapping.model';
import { DbSetting, PreviewDbFile, UploadFile } from '../../models/upload-file.model';
import { ResultFileModel } from '../../models/etl/result-file.model';
import { DataFormatModel } from '../../models/etl/date-format.model';
import { DataList } from '../../models/etl/maping-preview.model';
import { ConverSetting } from '../../models/etl/conver-setting.model';

@Injectable()
export class EtlService {
    constructor(private etlEndpoint: EtlEndpoint) {

    }
    getDataFormats() {
        return this.etlEndpoint.getDataFormatsEndpoint<DataFormatModel[]>();
    }
    getMappings() {
        return this.etlEndpoint.getMappingsEndpoint<MappingModel[]>();
    }
    
    getMapping(id: string) {
        return this.etlEndpoint.getMappingEndpoint<MappingModel>(id);
    }
    deleteMapping(id: string) {
        return this.etlEndpoint.getDeleteMappingEndpoint<MappingModel>(id);
    }
    getPatterns() {
        return this.etlEndpoint.getPatternsEndpoint<FilePattern[]>();
    }
    getPattern(id:number) {
        return this.etlEndpoint.getPatternEndpoint<FilePattern>(id);
    }
    updatePattern(id: number, item: FilePattern) {
        
        if (id)
            return this.etlEndpoint.getUpdatePatternEndpoint<FilePattern>(id, item);
        else 
            return this.etlEndpoint.getAddPatternEndpoint<FilePattern>(item);
        
    }

    deleteInputFile(id: string) {
        return this.etlEndpoint.getDeleteInputFileEndpoint<ResultFileModel>(id);

    }
    getInputFiles(type: string) {
        return this.etlEndpoint.getInputFilesEndpoint<UploadFile[]>(type);

    }
    fileInput(id: string) {
        return this.etlEndpoint.getFileIntputEndpoint<Blob>(id);

    }
    getFileInput(id: string) {
        return this.etlEndpoint.getFileInputInfoEndpoint<UploadFile>(id);

    }
    
    fileInputPreview(id: string, setting: ReadSetting) {
        return this.etlEndpoint.getFileIntputPreviewEndpoint<PreviewCsv>(id, setting);

    }
    

    fileOutput(id: string) {
        return this.etlEndpoint.getFileOutputEndpoint<Blob>(id);

    }

    addMaping(maping:MappingModel ): any {
        return this.etlEndpoint.getAddMappingEndpoint<MappingModel>(maping);
    }
    updateMaping(id:string,  maping: MappingModel): any {
        return this.etlEndpoint.getUpdateMappingEndpoint<MappingModel>(id, maping);
    }
    previewConvertMaping(maping: MappingModel): any {
        return this.etlEndpoint.getPreviewConvertMappingEndpoint<DataList[]>(maping);
    }
    convertFile(converSetting: ConverSetting) {
        return this.etlEndpoint.getConvertFileEndpoint<UploadFile[]>(converSetting);
    }
    fileOutputPreview(id: string) {
        return this.etlEndpoint.getOutputFilePreviewEndpoint<PreviewCsv>(id);

    }
    getResultFiles(mappingId: string) {
        return this.etlEndpoint.getResultFilesEndpoint<ResultFileModel[]>(mappingId);

    }
    testDbConnection(setting: DbSetting) {
        return this.etlEndpoint.testDbConnectionEndpoint<string>(setting);
    }
    importDbConnection(setting: DbSetting) {
        return this.etlEndpoint.importDbConnectionEndpoint<DbSetting>(setting);
    }
    importPreview(setting: DbSetting) {
        return this.etlEndpoint.importPreviewEndpoint<PreviewDbFile>(setting);
    }
    getDbSettings(types: string[]) {
        return this.etlEndpoint.getDbSettingsEndpoint<DbSetting[]>(types);
    }
}

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AlertService } from '../alert.service';

@Injectable()
export class ProductEndpoint extends EndpointBase {
   

    private readonly contractUrlPart: string = "/api/Contract";

    get contractUrl() { return this.configurations.baseUrl + this.contractUrlPart; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }

    getContractsEndpoint<T>(): Observable<T> {
        let endpointUrl = this.contractUrl;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getContractsEndpoint());
            }));
    }
    
    getAvailableContractsEndpoint<T>(): Observable<T> {
        let endpointUrl = this.contractUrl +"/available";
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getAvailableContractsEndpoint());
            }));
    }
    addContractEndpoint<T>(contactType:any): Observable<T> {

        let endpointUrl = this.contractUrl;
        return this.http.post<T>(endpointUrl, JSON.stringify(contactType), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.addContractEndpoint(contactType));
            }));
    }
    
    deleteContractEndpoint<T>(id: number): Observable<T> {

        let endpointUrl = this.contractUrl + `/${id}`;
        return this.http.delete<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.deleteContractEndpoint(id));
            }));
    }

    deleteRiderEndpoint<T>(id: number, productId: number, conractId: number): Observable<T> {
        let endpointUrl = this.contractUrl + `/${conractId}/product/${productId}/rider/${id}`;
        return this.http.delete<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.deleteRiderEndpoint(id, productId, conractId));
            }));
    }
    deleteProductEndpoint<T>(id: number, conractId: number): Observable<T> {
        let endpointUrl = this.contractUrl + `/${conractId}/product/${id}`;
        return this.http.delete<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.deleteProductEndpoint(id,  conractId));
            }));
    }
    updateContractEndpoint<T>(id: number, contactType: any): Observable<T> {

        let endpointUrl = this.contractUrl + `/${id}`;
        return this.http.put<T>(endpointUrl, JSON.stringify(contactType), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.updateContractEndpoint(id, contactType));
            }));
    }
}

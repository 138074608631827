import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AlertService } from '../alert.service';

@Injectable()
export class UserEndpoint extends EndpointBase {
   

    private readonly userUrlPart: string = "/api/User";

    get userUrl() { return this.configurations.baseUrl + this.userUrlPart; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }

    getActiveUsersEndpoint<T>(): any {
        let endpointUrl = this.userUrl+"/active";
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getActiveUsersEndpoint());
            }));
    }
    getUsersEndpoint<T>(): any {
        let endpointUrl = this.userUrl;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUsersEndpoint());
            }));
    }
    getDeleteUserEndpoint<T>(id: any): any {
        let endpointUrl = this.userUrl+`/${id}`;
        return this.http.delete<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getDeleteUserEndpoint(id));
            }));
    }
    getAddUserEndpoint<T>(user: any): any {
        let endpointUrl = this.userUrl;
        return this.http.post<T>(endpointUrl, JSON.stringify(user), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getAddUserEndpoint(user));
            }));
    }
    getUpdateUserEndpoint<T>(user: any, id: any): any {
        let endpointUrl = this.userUrl + `/${id}`;
        return this.http.put<T>(endpointUrl, JSON.stringify(user), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdateUserEndpoint(user, id));
            }));
    }
    getUpdateMyUserLang<T>( id: any): any {
        let endpointUrl = this.userUrl + `/my/language`;
        return this.http.put<T>(endpointUrl, JSON.stringify(id), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdateMyUserLang( id));
            }));
    }
    getUpdateMyUserTimeZone<T>(id: any): any {
        let endpointUrl = this.userUrl + `/my/time-zone`;
        return this.http.put<T>(endpointUrl, JSON.stringify(id), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdateMyUserTimeZone(id));
            }));
    }
    getUpdateUserGroupCompanyEndpoint<T>(group: any, id: any): any {
        let endpointUrl = this.userUrl + `/${id}/CompanyGroup`;
        return this.http.put<T>(endpointUrl, JSON.stringify(group), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdateUserGroupCompanyEndpoint(group, id));
            }));
    }
    getUserEndpoint<T>(id: any): any {
        let endpointUrl = this.userUrl + `/${id}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUserEndpoint(id));
            }));
    }
    getActionByUserEndpoint<T>(id: string, action: any): any {
        let endpointUrl = this.userUrl + `/action/${id}`;
        return this.http.post<T>(endpointUrl, JSON.stringify(action), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getActionByUserEndpoint(id, action));
            }));
    }
    getUserScheduleActions<T>(id: string): any {
        let endpointUrl = this.userUrl + `/actionSchedule/${id}`;
        return this.http.get<T>(endpointUrl,  this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUserScheduleActions(id));
            }));
    }
    
    getUserScheduleAllActions<T>(): any {
        let endpointUrl = this.userUrl + `/actionAllSchedule`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUserScheduleAllActions());
            }));
    }
    getUserActions<T>(id: string, onlyUpdateAction: boolean): any {
        let endpointUrl = this.userUrl + `/action/${id}`;
        if (onlyUpdateAction) endpointUrl+="/updateonly"
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUserScheduleActions(id));
            }));
    }
    
}

<div class="{{isModal?'':'loginPage loginModal'}}">



        <mat-card class="cardWithShadow ">

          <mat-card-content class="p-32 text-center">

            @if (formResetToggle) {
            <form class="m-t-30 m-b-30" name="loginForm" #f="ngForm" novalidate
                  (ngSubmit)="f.form.valid ? login() :
        (!email.valid && showErrorAlert(getTranslation('EmailRequired'), getTranslation('EnterEmailUsername')));
        (!password.valid && showErrorAlert(getTranslation('PasswordRequired'), getTranslation('EnterPassword')))">

              <div class="row m-b-32">
                <div class="offset-lg-3 col-lg-6 offset-md-1 col-md-10 offset-sm-0 col-sm-12">
                  <img src="../../assets/img//v2/logo_v2.svg" />
                  <div innerHTML="{{ 'login.title'  | translate}}">
                  </div>
                </div>
              </div>
              <div class="row m-t-32">
                <div class="offset-lg-3 col-lg-6 offset-md-1 col-md-10 offset-sm-0 col-sm-12">
                  <!-- email -->
                  <mat-form-field appearance="outline"
                                  class="w-100"
                                  color="primary">
                    <mat-label>{{'login.AssignedID' | translate}}</mat-label>
                    <input matInput required="" placeholder="User ID or Email"
                           [(ngModel)]="userLogin.email" name="email" #email="ngModel" />

                    @if (f.submitted && !email.valid) {
                    <mat-error>
                      {{'login.EmailUserRequired' | translate}}
                    </mat-error>
                    }

                  </mat-form-field>

                </div>

                <div class="col-1 text-left  p-0 p-t-16">
                  @if (f.submitted && email.valid) {
                  <mat-icon matSuffix class="text-success f-s-30 icon-30">check</mat-icon>
                  }
                  @else{
                  <mat-icon matSuffix class="text-info f-s-30 icon-30" [matTooltip]="'login.AssignedID' | translate">help</mat-icon>
                  }

                </div>
              </div>

              <div class="row">
                <div class="offset-lg-3 col-lg-6 offset-md-1 col-md-10 offset-sm-0 col-sm-12">
                  <!-- password -->
                  <mat-form-field appearance="outline"
                                  class="w-100"
                                  color="primary">
                    <mat-label>{{'login.Password' | translate}}</mat-label>
                    <input matInput type="password" required="" name="password" placeholder="Password" [(ngModel)]="userLogin.password" #password="ngModel" />

                    @if (f.submitted && !password.valid) {
                    <mat-error>
                      {{'login.PasswordRequired' | translate}}
                    </mat-error>
                    }
                  </mat-form-field>
                </div>

                <div class="col-1 text-left p-0 p-t-16">
                  @if (f.submitted && password.valid) {
                  <mat-icon matSuffix class="text-success f-s-30 icon-30">check</mat-icon>
                  }
                  @else{
                  <mat-icon matSuffix class="text-info f-s-30 icon-30" [matTooltip]="'login.Password' | translate">help</mat-icon>
                  }
                </div>
              </div>

              <!-- Environment -->

              <div class="row">
                <div class="offset-lg-3 col-lg-6 offset-md-1 col-md-10 offset-sm-0 col-sm-12">


                  <mat-form-field appearance="outline"
                                  class="w-100"
                                  color="primary">
                    <mat-label>Select Environment</mat-label>

                    <mat-select placeholder="Select Environment" name="deployment" id="deployment-drop" class="select-combo-white textInput" [(ngModel)]="userLogin.deployment"
                                #deployment="ngModel"
                                required>
                      <mat-option value="P">{{'login.Production' | translate}}</mat-option>
                      <mat-option value="D">{{'login.Development' | translate}}</mat-option>
                      <mat-option value="T">{{'login.Test' | translate}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-1 text-left p-0 p-t-16">

                  @if (f.submitted && deployment.valid) {
                  <mat-icon matSuffix class="text-success f-s-30 icon-30">check</mat-icon>
                  }

                  @if (f.submitted && !deployment.valid) {

                  <mat-error>
                    {{'login.EnvironmentRequired' | translate}}
                  </mat-error>
                  }

                </div>
              </div>
              <div class="row">
                <div class="offset-lg-3 col-lg-6 offset-md-1 col-md-10 offset-sm-0 col-sm-12">
                  <button type="submit" mat-flat-button extended 
                          color="accent"
                          class="w-100  d-flex justify-content-between"
                          [disabled]="isLoading || !f.form.valid">
                    @if (isLoading ) {
                    <mat-icon class="order-0">
                      <mat-spinner  diameter="15">
                      </mat-spinner>
                    </mat-icon>
                    }
                    @else{
                    <mat-icon></mat-icon>
                      }
                      <span class="order-1 text-center">
                        {{isLoading ? getTranslation('LogginIn') : getTranslation('Login')  }}
                      </span>

                      <mat-icon iconPositionEnd>arrow_forward</mat-icon>

                  </button>

                </div>
              </div>
              <div class="row m-32">
                <div class="offset-lg-3 col-lg-6 offset-md-1 col-md-10 offset-sm-0 col-sm-12">
                  @if(false){
                  <mat-checkbox color="primary" name="rememberMe" [(ngModel)]="userLogin.rememberMe">{{'login.RememberMe' | translate}}</mat-checkbox>
                  }
                  <a [routerLink]="'/forgot-password'"

                     class="text-primary   m-l-auto f-s-14">{{'login.ForgotPassword' | translate}}</a>

                </div>
              </div>

            </form>

            <div class="row m-16">
              <div class="offset-2 col-8 ">
                <div class="or-border m-t-30">or sign in with</div>
              </div>
            </div>
            <div class="row">
              <div class="offset-lg-3 col-lg-6 offset-md-1 col-md-10 offset-sm-0 col-sm-12 m-t-32">

                <button mat-flat-button  class="w-100 d-flex justify-content-between"
                        color="primary">
                  <mat-icon>

                    <img src="/assets/images/svgs/google-icon.svg"
                         alt="google"
                         width="16"
                         class="m-r-8" />
                  </mat-icon>
                  <span class="text-center">
                    Sign in with Google

                  </span>
                  <mat-icon iconPositionEnd></mat-icon>

                </button>
                <!--
      <div class="col-12 col-sm-6">
        <button mat-stroked-button
                class="w-100 d-flex align-items-center">
          <div class="d-flex align-items-center">
            <img src="/assets/images/svgs/facebook-icon.svg"
                 alt="facebook"
                 width="40"
                 class="m-r-4" />
            Sign in with FB
          </div>
        </button>
      </div>
        -->
              </div>
            </div>

            <div class="row">
              <div class="offset-lg-3 col-lg-6 offset-md-1 col-md-10 offset-sm-0 col-sm-12">

                <span class="d-block  d-block m-t-24">
                  <small>
                    version: <span class="f-w-500">{{version}}</span>
                  </small>
                </span>

              </div>
            </div>



            }




          </mat-card-content>
        </mat-card>

</div>


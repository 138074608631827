import { Injectable } from '@angular/core';

interface Scripts {
    group: string;
    name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
    { group: "mxgraph", name: 'mxgraph.config', src: '/assets/scripts/mxgraph.conf.js' },
    { group: "mxgraph", name: 'mxgraph', src: '/assets/mxgraph/mxClient.min.js' },
    { group: "mxgraph.shapes", name: 'mxgraph.shapes', src: '/assets/scripts/Shapes.js' },
    { group: "vis", name: 'vis', src: '/assets/vis/vis-network.min.js' },
    { group: "vis", name: 'vis.css', src: '/assets/vis/vis-network.min.css' },
    { group: "react-chart-editor", name: 'main.css', src: '/react-chart-editor.css' }
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
          src: script.src,
          group: script.group
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
    loadGroup(group: string) {
        const promises: any[] = [];
        let scripts = ScriptStore.filter(s => s.group == group);
        scripts.forEach((script) => promises.push(this.loadScript(script.name)));
        return Promise.all(promises);
    }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
        if (!this.scripts[name].loaded) {
            let src: string = this.scripts[name].src;
            if (src.endsWith(".css")) {
                var head = document.getElementsByTagName('head')[0];
                var link = document.createElement('link');
                link.id = this.scripts[name].name;
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = src;
                link.media = 'all';
                head.appendChild(link);
                this.scripts[name].loaded = true;
                resolve({ script: name, loaded: true, status: 'Loaded' });
            } else {
                //load script
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script.readyState) {  //IE
                    script.onreadystatechange = () => {
                        if (script.readyState === "loaded" || script.readyState === "complete") {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  //Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            }




      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

}

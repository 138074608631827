import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { PreviewCsv } from '../../models/etl/preview-csv.model';
import { ChartEndpoint } from './chart-endpoint.service';
import { ChartModel, ChartSearch } from '../../models/chart/chart.model';

@Injectable()
export class ChartService {
    constructor(private endpoint: ChartEndpoint) {

    }

    getCharts(search?: ChartSearch) {
        return this.endpoint.getChartsEndpoint<ChartModel[]>(search);
    }
    getChart(id: number) {
        return this.endpoint.getChartEndpoint<ChartModel>(id);
    }
    deleteChart(id: number) {
        return this.endpoint.getDeleteChartEndpoint<ChartModel>(id);
    }
    updateChart(item: ChartModel) {
        var updateItem: ChartModel = {
            id: item.id,
            name: item.name,
            batchProcessId: item.batchProcessId,
            processCode: item.processCode,
            processCodeId: item.processCodeId,
            chartFiles: item.chartFiles,
            options: item.options,
        };
        updateItem.user = null;
        if (updateItem.options && typeof updateItem != "string") {
            updateItem.options = JSON.stringify(updateItem.options);
        }
        if (updateItem.id)
            return this.endpoint.getUpdateChartEndpoint<ChartModel>(updateItem.id, updateItem);
        else
            return this.endpoint.getAddChartEndpoint<ChartModel>(updateItem);

    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PortfolioModel } from '../../models/company/portfolio.model';
import { UserGroup } from '../../models/Security/user-group.model';
import { SecurityEndpoint } from './security-endpoint.service';
import { CompanyUserGroup } from '../../models/Security/company-user-group.model';
import { UserEndpoint } from './user-endpoint.service';
import { AppUser, UserGroupCompany } from '../../models/Security/app-user.model';
import { UserAction } from '../../models/Security/user-action.model';
import { UserActionLog } from '../../models/Security/user-action-log.model';

@Injectable()
export class UserService {
    actionByUser(id: string, action: UserAction) {
        return this.userEndpoint.getActionByUserEndpoint<UserAction>(id, action);
    }
    constructor(private userEndpoint: UserEndpoint) {

    }

    getUsers() {
        return this.userEndpoint.getUsersEndpoint<AppUser[]>();
    }
    getActiveUsers() {
        return this.userEndpoint.getActiveUsersEndpoint<AppUser[]>();
    }
    getUser(id:string) {
        return this.userEndpoint.getUserEndpoint<AppUser>(id);
    }
    updateUser(id: string, item: AppUser) {
        if (id)
            return this.userEndpoint.getUpdateUserEndpoint<AppUser>(item, id);
        else
            return this.userEndpoint.getAddUserEndpoint<AppUser>(item);
    }

    deleteUser(id: string) {
        return this.userEndpoint.getDeleteUserEndpoint<AppUser>(id);
    }
    updateGroupCompany(id: string, item: UserGroupCompany) {
            return this.userEndpoint.getUpdateUserGroupCompanyEndpoint<AppUser>(item, id);
    }

    getUserActions(id: string, onlyUpdateAction: boolean) {
        return this.userEndpoint.getUserActions<UserActionLog>(id, onlyUpdateAction);
    }
    getUserScheduleActions(id: string) {
        return this.userEndpoint.getUserScheduleActions<UserActionLog>(id);
    }
    getUserScheduleAllActions() {
        return this.userEndpoint.getUserScheduleAllActions<UserActionLog>();
    }

    sendNewPassword(id: string) {
        var action = new UserAction(UserAction.ResetPassword);
        return this.actionByUser(id, action);
    }

    updateMyUserLang(id: number) {
        return this.userEndpoint.getUpdateMyUserLang<AppUser>(id);
    }
    updateMyUserTimeZone(id: number) {
        return this.userEndpoint.getUpdateMyUserTimeZone<AppUser>(id);
    }

}

export class UserIdleServiceConfig {
    /**
     * Idle value in seconds.
     */
    idle: number;
    /**
     * Timeout value in seconds.
     */
    timeout: number;
    /**
     * Ping value in seconds.
     */
    ping: number;
}
<div class="p-b-24 overflow-hidden">
  <div>
    <div class="row">
      <div class="col-lg-8 col-sm-6">
        <h4 class="page-title m-0 f-s-18 f-w-500">
          @if(pageInfo?.['mainTitle']){
          <span class="f-w-200 m-r-20">
            {{pageInfo?.['mainTitle']}}:
          </span>
          }
          <span [innerHTML]="pageInfo?.['title']">
          </span>


        </h4>

        <div class="d-flex align-items-center overflow-hidden justify-content-start list-style-none">
          <ul class="breadcrumb list-style-none d-flex p-l-0">
            @for (url of pageInfo?.['urls']; track url.url;let index = $index,last
            = $last ) {
            @if(!last){

            <li class="breadcrumb-item" [routerLink]="url.url">
              <a [routerLink]="url.url">{{ url.title }}</a>
            </li>
            }@else {
            <li class="breadcrumb-item active text-primary">{{ url.title }}</li>
            } }
          </ul>
          <mat-icon>user</mat-icon>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 text-right position-relative align-items-center justify-content-end gap-20 d-none d-sm-flex">
        <div class="d-flex align-items-center">
          <i-tabler name="{{pageInfo?.['icon']}}"
                    class="icon-56 text-warning"
                    matListItemIcon></i-tabler>

        </div>
      </div>



    </div>
  </div>
</div>

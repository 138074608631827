
<div class="row">
  <div class="col-lg-8 p-b-16">
    <home-box bgBox="bg-box-1" bgBoxHeader="bg-box-header-1"
      displayName="Administration"
              displayTitle="Manage your users"
              displayToolTip="Manage your users"
              iconName="users"
              image="pc"
              >
    </home-box>
 </div>
  <div class="col-lg-4 p-b-16">
    <home-box bgBox="" bgBoxHeader="bg-box-header-2"
              displayName="ETL"
              displayTitle="Select your functions and models"
              displayToolTip="Select your functions and models"
              >
    </home-box>
  </div>
</div>


<div class="row">
  <div class="col-lg-6 p-b-16">
    <home-box bgBox="bg-box-2" bgBoxHeader="bg-box-header-3"
              displayName="AuditReporting"
              displayTitle="Calculate your reserves"
              displayToolTip="Calculate your reserves"
              image="document">
    </home-box>

  </div>
  <div class="col-lg-6 p-b-16">
    <home-box bgBox="bg-box-3" bgBoxHeader="bg-box-header-4"
              displayName="CapitalRequirements"
              displayTitle="Calculate your risks"
              displayToolTip="Calculate your risks"
              image="cash">
    </home-box>

  </div>
</div>
<div class="row">
  <div class="col-lg-8 p-b-16">
    <home-box bgBox="" bgBoxHeader="bg-box-header-2"
              displayName="FunctionsModels"
              displayTitle="Select your functions and models"
              displayToolTip="Select your functions and models"
              image="search">
    </home-box>

  </div>
  <div class="col-lg-4 p-b-16">
    <home-box bgBox="bg-box-3" bgBoxHeader="bg-box-header-5"
              displayName="Reserves"
              displayTitle="Calculate your reserves"
              displayToolTip="Calculate your reserves"
              >
    </home-box>

  </div>
</div>
<div class="row">
  <div class="col-lg-6 p-b-16">
    <home-box bgBox="bg-box-3" bgBoxHeader="bg-box-header-5"
              displayName="DataVizAndAi"
              displayTitle="Select your functions and models"
              displayToolTip="Select your functions and models"
              image="chart">
    </home-box>

  </div>
  <div class="col-lg-6 p-b-16">
    <home-box bgBox="" bgBoxHeader="bg-box-header-6"
              displayName="AssetsManagement"
              displayTitle="Sous titre ALM et Pricing"
              displayToolTip="Sous titre ALM et Pricing"
              image="calc">
    </home-box>

  </div>

 </div>
  <!--
    <home-menu [menu]="[
             {displayName:'Administration'}
             ,{displayName:'AssetsManagement'}
             ,{displayName:'Reserves'}
             ,{displayName:'CapitalRequirements'}
             ,{displayName:'FunctionsModels'}
             ,{displayName:'AuditReporting'}
             ,{displayName:'DataVizAndAi'}
             ,{displayName:'Support'}
             ,{displayName:'ETL'}

             ]"></home-menu>
    -->

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left"><i class="fa fa-user-circle-o"></i> Add/Remove Chat Users</h4>
    <button type="button" class="close pull-right" title="{{ 'app.Close' | translate}}" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="popup_container container-fluid list-group-users">
      <div class="list-group ">
        @for (user of users; track user) {
        <div class="list-group-item CB_onlineUsers_user row">
          <div class="col-md-2 CB_onlineUsers_mute CB_onlineUsers_mute--muted js-CB_muteUser" title="Add/Remove">
            <div class="switchWrapper-onoffswitch onoffswitch onoffswitch--yesNo ">
              <input type="checkbox" class="onoffswitch-checkbox" [id]="'addUserId_' + user.id"
                     [disabled]="user.id==myId"
                     [(ngModel)]="selecrtUserIds[user.id]">
              <label class="onoffswitch-label" [for]="'addUserId_' + user.id">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <div class="col-md-8">
            <div class=" userRoundAvatar CB_userAvatar CB_onlineUsers_userAvatar"
                 [style.background-image]="getBgImage(user.pictureFileName)"></div>
            <span class="CB_onlineUsers_username">{{user.firstName}} {{user.lastName}}</span>
          </div>
          <div class="col-md-2">
            <div class="CB_onlineUsers_userStatus CB_onlineUsers_userStatus--{{user.status}}"></div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-4 col-xs-offset-4">
        <button type="button" (click)="addUsers()" class="s-btn s-btnBlue br4">
          Done
        </button>
      </div>
    </div>
  </div>
  </ng-template>
  

import { AccessTypeEnum, Permission } from '../../../../models/permission.model';
import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    displayName: 'Administration',
    displayTitle: 'Administration',
    displayToolTip: 'Administration',
    iconName: 'users-group',
    ddType: 'bg-primary',

    children: [
      {
        displayName: 'UserGroup',
        iconName: 'user',
        route: '/administration/company-definitions',
        permission: Permission.ManageSitesPolicyPermission
      },
      {
        displayName: 'ProductsDefinition',
        iconName: 'certificate',
        route: '/administration/products-definitions',
        permission: Permission.ProductDefinitionsPermission
      },
      {
        displayName: 'CreateUsers',
        iconName: 'user-plus',
        route: '/administration/user-profile',
        permission: Permission.UserManagementPermission,
        accessType: AccessTypeEnum.Write
      },
      {
        displayName: 'UserConsole',
        iconName: 'user-circle',
        route: '/administration/user-management',
        permission: Permission.UserManagementPermission,
      },
      {
        displayName: 'Batch',
        iconName: 'folders',
        route: '/calc/batch-scheduler',
        permission: Permission.BatchSchedulerPermission,
      },
    ],

  },

  
  {
    displayName: 'FunctionsModels',
    iconName: 'function',

    children: [
      {
        displayName: 'PointGenerator',
        iconName: 'windmill',
        permission: Permission.FormulasGeneratorPermission,
        children: [
          {
            displayName: 'Scalar',
            iconName: 'number',
            route: '/assets/liabilityscalar',
            permission: Permission.FormulasGeneratorPermission
          },
          {
            displayName: 'Vector',
            iconName: 'vector',
            route: '/assets/liabilityvector',
            permission: Permission.FormulasGeneratorPermission
          },
          {
            displayName: 'AssetsRisk',
            iconName: 'alert-circle',
            route: '/assets/assetsrisk',
            permission: Permission.FormulasGeneratorPermission
          },
          {
            displayName: 'AssetsRiskExotic',
            iconName: 'alert-triangle',
            route: '/assets/assetsriskexotic',
            permission: Permission.FormulasGeneratorPermission
          },
        ]

      },
      {
        displayName: 'CustomModeling',
        iconName: 'adjustments',
        permission: Permission.FormulasModelingStandardPermission,
        children: [
          {
            displayName: 'CodeEditor',
            iconName: 'code-asterisk',
            route: '/calc/cpp-module/file',
            permission: Permission.FormulasModelingStandardPermission
          },
          {
            displayName: 'ModelGenerator',
            iconName: 'rotate-3d',
            route: '/calc/cpp-module',
            permission: Permission.FormulasModelingStandardPermission
          },
          {
            displayName: 'CodeSnippets',
            iconName: 'code',
            route: '/calc/cpp-module/snippet',
            permission: Permission.FormulasModelingStandardPermission
          }
        ]

      },
      {
        displayName: 'RandomNumberGenerator',
        iconName: 'grain',
        route: '/calc/rng',
        permission: Permission.RandomNumberGeneratorPermission
      },
      {
        displayName: 'SequenceModel',
        iconName: 'brand-databricks',
        route: '/calc/sequence-modules',
        permission: Permission.WorkflowOrchestratorPermission
      }

    ]
  },
  

   {
     displayName: 'STOCHAcETL',
     iconName: 'outlet',
     ddType: 'bg-primary',
     permission: Permission.EtlTemplatePermission,
                     
    children: [
      {
        displayName: 'ETLTemplates',
        iconName: 'template',
        route: '/etl-tool/templates',
        permission: Permission.EtlTemplatePermission
      },
      {
        displayName: 'ETLEngine',
        iconName: 'engine',
        route: '/etl-tool',
        permission: Permission.EtlToolPermission
      },
      {
        displayName: 'ETLFiles',
        iconName: 'file-isr',
        route: '/etl-tool/files',
        permission: Permission.EtlToolPermission,
      }
    ],

  },

  

  {
    displayName: 'DataVizAndAi',
    iconName: 'database-cog',
    ddType: 'bg-primary',
    permission: Permission.BiNAnalyticsPermission,

    children: [
      {
        displayName: 'ReportsAnalytics',
        iconName: 'report-analytics',
        route: '/audit/chart',
        permission: Permission.BiNAnalyticsPermission
      },
      {
        displayName: 'DataWarehousing',
        iconName: 'building-warehouse',
        route: '/data/warehousing',
        permission: Permission.BiNAnalyticsPermission
      },
      {
        displayName: 'ArtificialIntelligenceBot',
        iconName: 'robot-face',
        route: '/calc/ai-bot',
        permission: Permission.BiNAnalyticsPermission,
      }
    ],
  },
   {
     displayName: 'Support',
     iconName: 'zoom-question',
    ddType: 'bg-primary',
     permission: Permission.SuggestFunctionalityPermission,

    children: [
      {
        displayName: 'NewFunctionality',
        iconName: 'script-plus',
        route: '/support/request-functionality',
        permission: Permission.SuggestFunctionalityPermission
      },
      {
        displayName: 'RemoteSupport',
        iconName: 'heart-handshake',
        route: '/support/remote-support',
        permission: Permission.RemoteSupportPermission

      },
      {
        displayName: 'ConsultingAssistance',
        iconName: 'help',
        route: '/support/consulting-assistance',
        permission: Permission.ConsultingRequestPermission,
      },
      {
        displayName: 'ChatRoom',
        iconName: 'message-chatbot',
        route: '/chatbot',
        permission: Permission.ChatRoomPermission,
      }
    ],

  },

  {
    displayName: 'AuditReporting',
    iconName: 'file-dots',
    ddType: 'bg-primary', // Assuming the dropdown type is the same as previous, adjust if needed
    permission: [
      Permission.AuditTrialPermission,
      Permission.FileTemplatePermission
    ],
    children: [
      {
        displayName: 'SystemAuditLogs',
        iconName: 'login-2', // Custom icon name, replace with appropriate value if needed
        route: '/audit/trail',
        permission: Permission.AuditTrialPermission
      },
      {
        displayName: 'Auditor',
        iconName: 'file-alert', // Custom icon name, replace with appropriate value if needed
        route: '/audit/auditor',
        permission: Permission.AuditTrialPermission
      },
      {
        displayName: 'FileRepository',
        iconName: 'file-description', // Custom icon name, replace with appropriate value if needed
        route: '/support/files-templates',
        permission: Permission.FileTemplatePermission
      }
    ]
  },
  {
    displayName: 'MarketValue',
    iconName: 'clipboard',
    ddType: 'bg-primary', // Assuming the dropdown type is the same as previous, adjust if needed
    permission: [
      Permission.McevPermission,
      Permission.McevAuditTrailPermission
    ],
    children: [
      {
        displayName: 'MCEVAnalysis',
        iconName: 'chart-arcs-3', // Custom icon name, replace with appropriate value if needed
        route: '/calc/mcev',
        permission: Permission.McevPermission
      },
      {
        displayName: 'MCEVTrail',
        iconName: 'route', // Custom icon name, replace with appropriate value if needed
        route: '/calc/mcev/audit-policy',
        permission: Permission.McevAuditTrailPermission
      }
    ]
  },
   {
  displayName: 'Reserves',
     iconName: 'reserved-line',
  ddType: 'bg-primary', // Assuming dropdown type, adjust if necessary
  permission: [
    Permission.TechnicalReservesPermission,
    Permission.ReservesAuditTrailPermission
  ],
  children: [
    {
      displayName: 'SavingsReserving',
      iconName: 'wave-square', // Custom icon, adjust as needed
      route: '/calc/reserves/creditor',
    },
    {
      displayName: 'PRCDC_cpp',
      iconName: 'brand-cpp', // Custom icon, adjust as needed
      route: '/calc/caci',
    },
    {
      displayName: 'NestedStochastic',
      iconName: 'augmented-reality-2', // Custom icon, adjust as needed
      route: '/calc/reserves/nested',
    },
    {
      displayName: 'StochasticGReserves',
      iconName: 'chart-arrows-vertical', // Custom icon, adjust as needed
      route: '/calc/reserves/generations',
    },
    {
      displayName: 'IFRSReserves',
      iconName: 'server-bolt', // Custom icon, adjust as needed
      route: '/calc/reserves/statutory',
      permission: Permission.TechnicalReservesPermission,
    }
  ]
  },
  {
    displayName: 'CapitalRequirements',
    iconName: 'calculator',
    ddType: 'bg-primary', // Assuming dropdown type, adjust if necessary
    permission: [
      Permission.GMMPermission,
      Permission.EmpiricalPermission,
      Permission.MarketRiskPermission,
      Permission.CreditRiskPpricingPermission
    ],
    children: [
      {
        displayName: 'UnderwritingRisk',
        iconName: 'writing-sign', // Custom icon, adjust as needed
        children: [
          {
            displayName: 'GMMMethod',
            iconName: 'meteor', // Custom icon, adjust as needed
            route: '/calc/ur-gmm-method',
            permission: Permission.GMMPermission,
          },
          {
            displayName: 'EmpiricalMethod',
            iconName: 'logic-and', // Custom icon, adjust as needed
            route: '/calc/ur-empirical-method',
            permission: Permission.EmpiricalPermission,
          }
        ]
      },
      {
        displayName: 'MarketRisk',
        iconName: 'trolley', // Custom icon, adjust as needed
        route: '/calc/market-risk',
        permission: Permission.MarketRiskPermission,
      },
      {
        displayName: 'CreditRisk',
        iconName: 'credit-card-pay', // Custom icon, adjust as needed
        route: '/calc/credit-risk',
        permission: Permission.CreditRiskPpricingPermission,
      }
    ]
  }, {
    displayName: 'HPCUserSettings',
    iconName: 'settings',
    ddType: 'bg-primary', // Assuming dropdown type, adjust if necessary
    permission: [
      Permission.UserSettingsPermission,
      Permission.SystemMaintenancePermission
    ],
    children: [
      {
        displayName: 'HPCConsole',
        iconName: 'terminal', // Custom icon, adjust as needed
        route: '/settings/system-maintenance',
        permission: Permission.SystemMaintenancePermission,
      },
      {
        displayName: 'LanguagesTimeZones',
        iconName: 'language', // Custom icon, adjust as needed
        route: '/settings/language-settings',
        permission: Permission.UserSettingsPermission,
      },
      {
        displayName: 'SelfTesting',
        iconName: 'settings-check', // Custom icon, adjust as needed
        route: '/settings/full-tests',
        permission: Permission.UserManagementPermission,
      },
      {
        displayName: 'Servers',
        iconName: 'server-cog', // Custom icon, adjust as needed
        children: [
          {
            displayName: 'Servers',
            iconName: 'server-bolt', // Custom icon, adjust as needed
            route: '/calc/cpp-module/servers',
            permission: Permission.CppServersPermission,
          },
          {
            displayName: 'GroupServers',
            iconName: 'brand-graphql', // Custom icon, adjust as needed
            route: '/calc/cpp-module/group-servers',
            permission: Permission.CppServersPermission,
          },
          {
            displayName: 'ModuleSetting',
            iconName: 'device-mobile-star', // Custom icon, adjust as needed
            route: '/calc/cpp-module/calc-modules',
            permission: Permission.CppServersPermission,
          }
        ]
      }
    ]
  }

];

export const navAddItems: NavItem[] = [
{
  displayName: 'AssetsManagement',
    iconName: 'notebook',
  ddType: 'bg-primary', // Assuming dropdown type, adjust if necessary
  permission: [
    Permission.AssetLiabilityModelingPermission,
    Permission.RAssetLiabilityModelingPermission,
    Permission.McevPermission
  ],
  children: [
    {
      displayName: 'PricingBuilder',
      iconName: 'building-skyscraper', // Custom icon, adjust as needed
      route: '/calc/partner-re',
      permission: Permission.AssetLiabilityModelingPermission,
    },
    {
      displayName: 'PricingBuilderDB',
      iconName: 'file-database', // Custom icon, adjust as needed
      route: '/calc/partner-re/lab',
      permission: Permission.AssetLiabilityModelingPermission,
    },
    {
      displayName: 'ALMFactors',
      iconName: 'asset', // Custom icon, adjust as needed
      route: '/calc/alm',
      permission: Permission.AssetLiabilityModelingPermission,
    },
    {
      displayName: 'r-alm',
      iconName: 'circle-letter-r', // Custom icon, adjust as needed
      route: '/calc/alm/r',
      permission: Permission.RAssetLiabilityModelingPermission,
    },
    {
      displayName: 'MCEVAnalysis',
      iconName: 'presentation-analytics', // Custom icon, adjust as needed
      route: '/calc/mcev',
      permission: Permission.McevPermission,
    }
  ]
  },
  {
    displayName: 'ETL',
    iconName: 'outlet',
    ddType: 'bg-primary',
    permission: Permission.EtlTemplatePermission,

    children: [
      {
        displayName: 'ETLTemplates',
        iconName: 'template',
        route: '/etl-tool/templates',
        permission: Permission.EtlTemplatePermission
      },
      {
        displayName: 'ETLEngine',
        iconName: 'engine',
        route: '/etl-tool',
        permission: Permission.EtlToolPermission
      },
      {
        displayName: 'ETLFiles',
        iconName: 'file-orientation',
        route: '/etl-tool/files',
        permission: Permission.EtlToolPermission,
      }
    ],

  },


]

import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { CoreService } from '../../services/core.service';
import { AppSettings } from '../../app.config';
import { navItems } from './vertical/sidebar/sidebar-data';
import { NavService } from '../../services/nav.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {
  AppSearchDialogComponent,
  HeaderComponent,
} from './vertical/header/header.component';
import { AppHorizontalHeaderComponent } from './horizontal/header/header.component';
import { AppHorizontalSidebarComponent } from './horizontal/sidebar/sidebar.component';
import { SidebarComponent } from './vertical/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './shared/customizer/customizer.component';
import { MaterialModule } from '../../material.module';
import { ActivatedRoute, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AppNavItemComponent } from './vertical/sidebar/nav-item/nav-item.component';
import { IconsModule } from '../../icon.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccessTypeEnum, PermissionValues } from '../../models/permission.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { AlertDialog, AlertService, DialogType, AlertMessage, MessageSeverity } from '../../services/alert.service';
import { UserIdleService } from '../../services/user-idle/user-idle.service';
import { AccountService } from '../../services/account.service';
import { AppTitleService } from '../../services/app-title.service';
import { AuthService } from '../../services/auth.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { InfoHubService } from '../../services/info-hub.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LoginComponent } from '../../components/login/login.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


import { LoadingComponent } from '../../components/loading/loading.component';
import { ToastrService, IndividualConfig, ActiveToast } from 'ngx-toastr';

import { take } from 'rxjs';
import { DeferBlockBehavior } from '@angular/core/testing';
import { CommonDialogComponent } from '../../components/controls/common-dialog.component';

const MOBILE_VIEW = 'screen and (max-width: 768px)';
const TABLET_VIEW = 'screen and (min-width: 769px) and (max-width: 1024px)';
const MONITOR_VIEW = 'screen and (min-width: 1024px)';
const BELOWMONITOR = 'screen and (max-width: 1023px)';

//TODO: need move to MatUI
// var alertify: any = require('../../assets/scripts/alertify.js');


@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  standalone: true,
  providers: [InfoHubService, UserIdleService],
  imports: [
    NgScrollbarModule,
    HeaderComponent,
    AppHorizontalHeaderComponent,
    AppHorizontalSidebarComponent,
    SidebarComponent,
    AppBreadcrumbComponent,
    AppSearchDialogComponent,
    CustomizerComponent,
    MaterialModule,
    RouterModule,
    CommonModule,
    AppNavItemComponent,
    IconsModule
  ],
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class FullComponent implements OnInit, OnDestroy {
  navItems = navItems;
  @ViewChild('leftsidenav')
  public sidenav: MatSidenav;
  resView = false;
  //get options from service
  options = this.settings.getOptions();
  navopt = this.navService.showClass;
  private layoutChangesSubscription = Subscription.EMPTY;
  private isMobileScreen = false;
  private isContentWidthFixed = true;
  private isCollapsedWidthFixed = false;
  private htmlElement!: HTMLHtmlElement;


  /* From old code*/

  AccessTypeEnum = AccessTypeEnum;

  loadModal: MatDialogRef<LoadingComponent, any>;
  loadingRouteConfig: boolean;
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  isAnonymousPage: boolean = false;
  anonymousPages = ['reset-password', 'login'];
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  appTitle = "StochaC";

  stickyToasties: number[] = [];



  // @ViewChild('selectCompanies')     selectCompanies: BootstrapSelectDirective;

  // @ViewChild(MainMenuDirective) mainMenuCompanies: MainMenuDirective;


  loginControl: LoginComponent | undefined;
  gT = (key: string | Array<string>, interpolateParams?: object) => this.translationService.getTranslation(key, interpolateParams);



  get isOver(): boolean {
    return this.isMobileScreen;
  }

  get isTablet(): boolean {
    return this.resView;
  }

  constructor(
    private settings: CoreService,
    private mediaMatcher: MediaMatcher,
    private navService: NavService,
    private breakpointObserver: BreakpointObserver,
    private snackBar: MatSnackBar,
    /*old */
    /* Need remove ToastrService */
    private ToastaService: ToastrService,
    private storageManager: LocalStoreManager,
    private accountService: AccountService,
    private alertService: AlertService,
    private modalService: MatDialog,

    private appTitleService: AppTitleService,
    private authService: AuthService, private translationService: AppTranslationService, public configurations: ConfigurationService,
    public router: Router, private route: ActivatedRoute, private infoHubService: InfoHubService
    , private userIdle: UserIdleService
  ) {
    this.htmlElement = document.querySelector('html')!;
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW, BELOWMONITOR])
      .subscribe((state) => {
        // SidenavOpened must be reset true when layout changes
        this.options.sidenavOpened = true;
        this.isMobileScreen = state.breakpoints[BELOWMONITOR];

        if (this.options.sidenavCollapsed == false) {
          this.options.sidenavCollapsed = state.breakpoints[TABLET_VIEW];
        }
        this.isContentWidthFixed = state.breakpoints[MONITOR_VIEW];
        this.resView = state.breakpoints[BELOWMONITOR];
      });

    // Initialize project theme with options
    this.receiveOptions(this.options);

    storageManager.initialiseStorageSyncListener();

    translationService.addLanguages(["en", "fr", "de", "pt", "ar", "ko"]);
    translationService.setDefaultLanguage('en');



    this.appTitleService.appName = this.appTitle;

    this.initIdle();

  }


  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();

  }

  toggleCollapsed() {
    this.isContentWidthFixed = false;
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }

  resetCollapsedState(timer = 400) {
    setTimeout(() => this.settings.setOptions(this.options), timer);
  }

  onSidenavClosedStart() {
    this.isContentWidthFixed = false;
  }

  onSidenavOpenedChange(isOpened: boolean) {
    this.isCollapsedWidthFixed = !this.isOver;
    this.options.sidenavOpened = isOpened;
    this.settings.setOptions(this.options);
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }


  /* old code */

  initIdle() {
    this.userIdle.onTimerStart().subscribe(count => {
      if (count == 1) {
        this.alertService.resetStickyMessage();
        this.alertService.showStickyMessage(
          this.translationService.getTranslation('main.SessionLogout'),
          this.translationService.getTranslation('main.WillLogoutAutomatically'),
          MessageSeverity.wait);
      }
      if (!count) {
        this.alertService.resetStickyMessage();
      }

    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.alertService.resetStickyMessage();
      this.authService.logout();
      this.authService.redirectLogoutUser();
      this.infoHubService.stop();

    });
  }
  /*
  ngAfterViewInit() {
      this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
          controls.forEach(control => {
              if (control) {
                  if (control instanceof LoginComponent) {
                      this.loginControl = control;
                      this.loginControl.modalClosedCallback = () => this.loginModal.hide();
                  }
                  else {
                      this.loginModal = control;
                      this.loginModal.show();
                  }
              }
          });
      });
  }
  */
  localize() {
    //alert($('div').length);

  }

  onLoginModalShown() {
    this.alertService.showStickyMessage(this.translationService.getTranslation('main.SessionExpired'),
      this.translationService.getTranslation('main.SessionExpiredLogAgain'), MessageSeverity.info);
  }


  onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    if (this.authService.isSessionExpired)
      this.alertService.showStickyMessage(this.translationService.getTranslation('main.SessionExpired'),
        this.translationService.getTranslation('main.RenewSession'), MessageSeverity.warn);
  }


  onLoginModalHide() {
    this.alertService.resetStickyMessage();
  }
  refresh() {
    this.authService.sendLoginStatus();
    var isAllow = this.accountService.userHasPermission(this.appTitleService.permission);
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    this.router.navigated = false;
    if (isAllow)
      this.router.navigateByUrl(this.router.url);
    else {
      this.router.navigateByUrl("/");
      this.alertService.showMessage(
        this.translationService.getTranslation('main.AccessDenied'),
        this.translationService.getTranslation('main.NotPermissionPage', this.appTitleService),
        MessageSeverity.warn);

    }

  }
  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;

    // 1 sec to ensure all the effort to get the css animation working is appreciated :|, Preboot screen is removed .5 sec later
    setTimeout(() => this.isAppLoaded = true, 1000);
    setTimeout(() => this.removePrebootScreen = true, 1500);

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();

        //if (!this.authService.isSessionExpired)
        this.alertService.showMessage(
          this.translationService.getTranslation('app.Login'),
          this.translationService.getTranslation('main.WelcomeBack', { userName: this.userName }),
          MessageSeverity.default);
        //else
        //    this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again", MessageSeverity.warn);
      }
    }, 2000);


    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
    this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));

    this.authService.reLoginDelegate = () => this.openLoginModal();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let url = (<NavigationStart>event).url;
        let lUrl = url.toLowerCase();
        this.isAnonymousPage = false;
        if (this.anonymousPages.find(p => lUrl.indexOf(p) >= 0)) {
          this.isAnonymousPage = true;
          return;
        }
        if (url !== lUrl) {
          this.router.navigateByUrl((<NavigationStart>event).url.toLowerCase());
        }
        if (isPlatformBrowser) {
          window.scrollTo(0, 0);
          /*
          if (this.mainMenuCompanies) {
            this.mainMenuCompanies.closeMenu();
          }
          */
        }
      }
      else if (event instanceof NavigationEnd) {
        this.localize();
      } else if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;

        let modal = this.modalService.open(LoadingComponent, { panelClass: 'loading-dialog' });
        this.loadModal = modal;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
        setTimeout(() => {
          this.loadModal.close();
        }, 500);

      }
    });

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;
      if (this.isAnonymousPage) return;

      if (this.isUserLoggedIn) {
        this.authService.companyListChanged();
        this.userIdle.startWatching();
        this.configurations.language = this.authService.currentUser.language;
      }
      else {
        this.userIdle.stopWatching();
      }

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage(
            this.translationService.getTranslation('main.SessionEnded')
            , "", MessageSeverity.default);
        }
      }, 500);

    });

    /*
    if (typeof (document) !== 'undefined') {
        if (this.authService.isLoggedIn)
            document.body.className = "";
        else
            document.body.className = "loginPage";
    }
    */
  }








  openLoginModal() {
    const modalRef = this.modalService.open(LoginComponent, {
      panelClass: 'login-dialog'
    });

    this.loginControl = modalRef.componentInstance as LoginComponent;
    this.loginControl.isModal = true;

    this.loginControl.modalClosedCallback = () => modalRef.close();
    modalRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });

    modalRef.afterOpened().subscribe(() => {
      this.alertService.showStickyMessage(this.gT('app.alerts.SessionExpired'), this.gT('app.alerts.SessionExpiredLoginAgain'), MessageSeverity.info);
    });

    modalRef.afterClosed().subscribe((result) => {
      this.alertService.resetStickyMessage();
      this.loginControl?.reset();

      if (this.authService.isSessionExpired) {
        this.alertService.showStickyMessage(this.gT('app.alerts.SessionExpired'), this.gT('app.alerts.SessionExpiredLoginToRenewSession'), MessageSeverity.warn);
      }
    });
  }

  showDialog(dialog: AlertDialog) {
    debugger;
    dialog.okLabel = dialog.okLabel || "OK";
    dialog.cancelLabel = dialog.cancelLabel || "Cancel";

    let modal = this.modalService.open(CommonDialogComponent, {
      data: dialog,
      panelClass: 'custom-dialog'
      // minWidth: 200, minHeight: 100
    });

    switch (dialog.type) {
      case DialogType.alert:


        break
      case DialogType.confirm:
        modal.afterClosed().subscribe(e => {
          if (e) {
            dialog.okCallback();
          }
          else {
            if (dialog.cancelCallback)
              dialog.cancelCallback();
          }
        });

        break;
      case DialogType.prompt:
        modal.afterClosed().subscribe(e => {
          if (e) {
            dialog.okCallback();
          }
          else {
            if (dialog.cancelCallback)
              dialog.cancelCallback();
          }
        });
        modal.afterClosed().subscribe((val) => {
          if (val) {
            dialog.okCallback(val);
          }
          else {
            if (dialog.cancelCallback)
              dialog.cancelCallback();
          }
        });

        break;
    }
  }





  showToast(message: AlertMessage, isSticky: boolean) {

    if (message == null) {
      for (let id of this.stickyToasties.slice(0)) {
        this.ToastaService.clear(id);
      }

      return;
    }

    let toastOptions = { timeOut: isSticky ? 0 : 4000 };

    let severity: string = 'info';
    let toasta: ActiveToast<any>;
    switch (message.severity) {
      case MessageSeverity.default:
      case MessageSeverity.info:
        toasta = this.ToastaService.info(message.detail, message.summary, toastOptions);
        severity = 'info';
        break;
      case MessageSeverity.success:
        toasta = this.ToastaService.success(message.detail, message.summary, toastOptions);
        severity = 'success';
        break;
      case MessageSeverity.error:
        toasta = this.ToastaService.error(message.detail, message.summary, toastOptions);
        severity = 'error';
        break;
      case MessageSeverity.warn:
        toasta = this.ToastaService.warning(message.detail, message.summary, toastOptions);
        severity = 'warn';
        break;
      case MessageSeverity.wait:
        toasta = this.ToastaService.info(message.detail, message.summary, toastOptions);
        severity = 'info';
        break;
    }

    // let toasta = this.ToastaService.show(message.detail, message.summary, toastOptions, severity);

    if (isSticky) {
      toasta.onAction.pipe(take(1))
        .subscribe(() => this.stickyToasties.push(toasta.toastId));

      toasta.onHidden.pipe(take(1))
        .subscribe(() => {
          let index = this.stickyToasties.indexOf(toasta.toastId, 0);

          if (index > -1) {
            this.stickyToasties.splice(index, 1);
          }

        });
    }

  }





  logout() {
    console.log("logout");
    this.alertService.showDialog(
      this.translationService.getTranslation('main.SureLogout'),
      DialogType.confirm, () => {
        this.authService.logout();
        this.authService.redirectLogoutUser();
      }
    );

    return false;
  }


  getYear() {
    return new Date().getUTCFullYear();
  }


  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : "";
  }
  get userBgImage(): string {

    let picture = this.authService.currentUser ? this.authService.currentUser.picture : "";
    if (picture) {
      return `${this.configurations.baseUrl}/Uploads/UserLogo/${picture}`;
    } else {
      return '';
    }
  }


  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }



  public canView(value: PermissionValues, accessType?: AccessTypeEnum): boolean {
    return this.accountService.userHasPermission(value, accessType)
  }
  public canViewAny(values: PermissionValues[]): boolean {
    var isAllow = false;
    for (var value of values) {
      if (this.accountService.userHasPermission(value)) return true;
    }
    return false;
  }
  public expandMenu(displayName:string) {
    for (var m of this.navItems) {
      m.expanded = m.displayName == displayName;
    }
  }


}

import { Component, QueryList, ViewChildren, ViewChild, OnInit, AfterViewInit, ElementRef, NgZone } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { ProductService } from '../../services/company/product.service';
import { UserService } from '../../services/company/user.service';
import { Utilities } from '../../services/utilities';
import { AppUser } from '../../models/Security/app-user.model';
import { Company } from '../../models/company.model';
import { UserGroup } from '../../models/Security/user-group.model';
import { SecurityService } from '../../services/company/security.service';
import { CompanyService } from '../../services/company/company.service';
import { SearchInResultComponent } from '../controls/search-in-result.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ConfigurationService } from '../../services/configuration.service';
import { ScrollTopComponent } from '../controls/scroll-top.component';
import { UserAction } from '../../models/Security/user-action.model';
import { UserActionLog } from '../../models/Security/user-action-log.model';
import { BatchJob, BatchProcess, ResultFile } from '../../models/batch/batch-job.model';
import { AccountService } from '../../services/account.service';
import { BatchService } from '../../services/batch/batch.service';
import { CalcService } from '../../services/calc.service';
import { InfoHubService } from '../../services/info-hub.service';
import { ChatInfo, ChatUpdateInfo, ChatUserSetting } from '../../models/Hub/char-info.model';
import { Message, TypingStatus } from '../../models/Hub/Message.model';
import * as $ from 'jquery';
import 'blueimp-file-upload/js/jquery.fileupload.js';
import { UploadFile } from '../../models/upload-file.model';
import { AuthService } from '../../services/auth.service';
import { UploadFileInfo } from '../controls/upload-files.component';
import { FileService } from '../../services/file.service';
import * as FileSaver from 'file-saver';
import { SimpleBarDirective } from '../../directives/simple-bar.directive';
import { UpdateUsersComponent } from '../controls/chat/update-users.component';
import { Route, ActivatedRoute, RouterLink } from '@angular/router';
import { UpdateTitleComponent } from '../controls/chat/update-title.component';
import { AppTranslationService } from '../../services/app-translation.service';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FileSizePipe } from '../../pipes/file-size.pipe';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'chatbot',
    templateUrl: './chatbot.component.html',
  animations: [fadeInOut],
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, FileSizePipe, UpdateUsersComponent, DecimalPipe
    , MatSelectModule, SimpleBarDirective, FormsModule, ScrollTopComponent, UpdateTitleComponent]

})
export class ChatBotComponent implements OnInit, AfterViewInit {
    activeChat: ChatInfo;
    chats: ChatInfo[] = [];
    users: AppUser[] = [];
    chatSettings: ChatUserSetting[] = [];
    //TEmp need remoe to Chats
    messages: Message[] = [];
    myStatus = "online";
    message = "";
    @ViewChild(ScrollTopComponent) scrollTop: ScrollTopComponent;
    @ViewChild('addFile') addFile: ElementRef;
    messageFile: UploadFile = { fileName: '', size: 0 };
    activeUser: AppUser = {};
    chatId: string;
    myId: string;
    
    @ViewChild('simpleBar')
    simpleBar: SimpleBarDirective;
    typingTimeout: number;
    typingInTimeout: number;
    typingStatus: TypingStatus;


    initChatId: string;
    @ViewChild('updateUsersChat')
    updateUsersChat: UpdateUsersComponent;
    
    @ViewChild('updatetitle')
    updatetitle: UpdateTitleComponent;


    constructor(private alertService: AlertService,
        private configurations: ConfigurationService,
        private authService: AuthService,
        private infoHubService: InfoHubService,
        private route: ActivatedRoute,
        private translationService: AppTranslationService,
        private zone: NgZone) {
    }

    ngAfterViewInit(): void {
        this.functionInitUpload();
    }
    ngOnInit(): void {
        this.initChatId = this.route.snapshot.paramMap.get('id')

        if (this.authService.currentUser) {
            this.myId = this.authService.currentUser.id;
            this.users = this.infoHubService.users;
        } else {
            this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    this.myId = this.authService.currentUser.id;

                }
            });
        }
        /*
        this.userService.getUsers().subscribe(items => {
            this.users = items;
            debugger;
        });
        */
        this.infoHubService.getStartedEvent().subscribe(status => {
            this.InitConnection();
        });
        if (this.infoHubService.isConnected) this.InitConnection();
        this.infoHubService.getMyStatus().subscribe(status => {
            //this.myStatus = this.infoHubService.getStatus;
        });
        this.infoHubService.getMessage().subscribe(message => {
            // this.messages.push(message);
            this.simpleBar.toEnd();

        });
        this.infoHubService.getStatus().subscribe(status => {
        });
        this.infoHubService.getTypingStatus().subscribe(status => {
            this.showTyping(status);
        });
        this.infoHubService.getUserStatus().subscribe(users => {
            this.users = users;
            if (this.activeUser == null && this.users.length > 0) this.activeUser = this.users[0];
        });

        this.infoHubService.getNewChat().subscribe(activeChat => {
            this.activeChat = activeChat;
            this.chats = this.infoHubService.chats;
            this.updateChatInfo();
        });
        this.infoHubService.getAddToChat().subscribe((activeChat: ChatInfo) => {
            if (!activeChat) return;
            this.chats = this.infoHubService.chats;
            this.alertService.showStickyMessage(
                this.translationService.getTranslation("chat.YouAdd2Chat")
                , "", MessageSeverity.info);
        });
        this.infoHubService.getRemoveFromChat().subscribe((activeChat: ChatInfo) => {
            this.chats = this.infoHubService.chats;
            this.alertService.showStickyMessage("You will remove from chat", "", MessageSeverity.warn);
        });
        this.infoHubService.getUpdateChatId().subscribe((updateInfo: ChatUpdateInfo) => {
            this.chats = this.infoHubService.chats;
        });

        this.infoHubService.getUpdateChatTitle().subscribe((activeChat: ChatInfo) => {
            if (this.activeChat && this.activeChat.id == activeChat.id) {
                this.activeChat = activeChat;
            }
            this.chats = this.infoHubService.chats;
           
        });
        
        this.infoHubService.getUpdateChatListEvent().subscribe((chatInfo) => {
            this.chats = this.infoHubService.chats;
        });
        this.infoHubService.getDeleteChatEvent().subscribe((chatInfo) => {
            this.chats = this.infoHubService.chats;
            if (this.activeChat.id == chatInfo.id) {
                this.activeChat = null;
            }

        });

        this.infoHubService.getChatSettings().subscribe((items) => {
            this.chatSettings = items;

        });
        this.infoHubService.getDeleteMessageEvent().subscribe((message:Message) => {
            var chat = this.chats.find(c => c.id == message.chatId);
            if (chat && chat.messages) {
                chat.messages.find(m => m.id == message.id).deleted = true;
            }

        });


    }
    InitConnection() {
        this.users = this.infoHubService.users;
        this.myStatus = this.infoHubService.myStatus;
        this.initChatId = this.route.snapshot.paramMap.get('id')
        if (this.initChatId) {
            this.activeChat = this.infoHubService.chats.find(c => c.id == this.initChatId);
            if (this.activeChat)
                this.updateChatInfo();
            this.infoHubService.setActiveChat("", this.initChatId);
        }
        this.infoHubService.loadChats();
        this.chatSettings = this.infoHubService.chatSettings;

    }
    deleteMessage(message:Message) {
        this.infoHubService.deleteMessage(message.id);
        return false;
    }
    updateChatInfo() {
        this.messages = this.activeChat.messages;
        this.simpleBar.toEnd();

    }
    getUsersOnlineCount() {
        var users = this.users.filter(u => u.id != this.myId && u.status == "online");
        return users.length;
    }
    getUsersAndChat() {
        var users = this.users.filter(u => u.id != this.myId);
        return users;
    }
    getActiveGroupChat() {
        return this.chats.filter(c => c.isGroup).sort((a, b) => {
            var ta = new Date(a.lastActive ? a.lastActive : a.endDate);
            var tb = new Date(b.lastActive ? b.lastActive : b.endDate);
            return tb.getTime() - ta.getTime();
        });
    }
    getChatUsers(chat: ChatInfo) {
        if (!chat) return [];
        return chat.users.filter(u => u.id != this.myId);
    }
    getBgImage(image: string) {
        if (image)
            return `url(${this.configurations.baseUrl}/Uploads/UserLogo/${image})`;
        return null;
    }
    setStatus() {
        this.infoHubService.myStatus = this.myStatus;
    }
    sendMessage() {
        if (!this.message && !this.messageFile.id) {
            return;
        }
        let message: string = this.message;
        message = message.trim()
        this.infoHubService.sendMessage(this.activeUser.id, this.activeChat.id, message, this.messageFile.id);
        this.message = "";
        this.clearSendFile();
        clearTimeout(this.typingTimeout);
    }
    public openUserChat(user: AppUser) {
        this.activeUser = user;
        let chat = this.chats.find(c => !c.isGroup && c.users.find(u => u.id == user.id) != null);
        this.activeChat = chat;
        this.infoHubService.setActiveChat(user.id, "");
    }
    public openChat(chat: ChatInfo) {
        this.activeChat = chat;
        this.infoHubService.setActiveChat("", chat.id);
    }
    loadMessageFile(file: UploadFile) {
        this.infoHubService.loadMessageFile(file);
        return false;
    }
    public clearSendFile() {
        let divFile = $(this.addFile.nativeElement);
        let btn = divFile.find(".addFileBtn");
        let loading = divFile.find(".addFileLoading");
        let info = divFile.find(".addFileInfo");
        btn.show();
        loading.hide();
        info.hide();
        this.messageFile = {};
    }
    public uploadFile() {
        let divFile = $(this.addFile.nativeElement);
        let input = divFile.find(".addFileInput");
        input.click();
    }
    public onMessageTyping(event) {
        clearTimeout(this.typingTimeout);
        this.typingTimeout = window.setTimeout(() => {
            this.infoHubService.sendTyping("typing", this.activeChat.toUserId, this.activeChat.id);
        },2000);
    }
    showTyping(status: TypingStatus) {
        if (this.activeChat && this.activeChat.id == status.chatId) {
            this.typingStatus = status;
            clearTimeout(this.typingInTimeout);
          this.typingInTimeout = window.setTimeout(() => {
                this.typingStatus = null;
            }, 5000);
        }
    }

    private functionInitUpload() {
        let divFile = $(this.addFile.nativeElement);
        let btn = divFile.find(".addFileBtn");
        let input = divFile.find(".addFileInput");
        
        let loading = divFile.find(".addFileLoading");
        let info = divFile.find(".addFileInfo");
        (<any>input).fileupload({
            dataType: 'json',
            url: this.configurations.baseUrl + `/api/Chat/File/`,
            maxChunkSize: 2000000,
            //sequentialUploads: true,
            beforeSend: (xhr) => {
                xhr.setRequestHeader("Authorization", "Bearer " + this.authService.accessToken);
                xhr.setRequestHeader("User-Deployment", this.authService.deployment);
                xhr.setRequestHeader("User-CompanyId", " " + this.authService.companyId);
            },
            progressall: (e, data: any) => {
                let loaded: number = parseInt(data.loaded);
                let total: number = parseInt(data.total);
                var progress = Math.round(loaded / total * 100);
                loading.show();
                btn.hide();
                info.hide();
                /*
                $(barId + ' .bar').css(
                    'width',
                    progress + '%'
                );
                */
            },
            done: (e, data) => {
                this.messageFile = data.result.files[0];
                this.zone.run(() => {
                    info.show();
                    loading.hide();
                    btn.hide();

                });

            },
            error: (jqXHR, textStatus, errorThrown) => {
                btn.show();
                loading.hide();
                info.hide();
                var error = null;
                if (jqXHR.responseJSON)
                    error  = jqXHR.responseJSON.ChatFile;
                if (!error) error = jqXHR.responseText;
                if (!error) error = errorThrown;
                this.alertService.showStickyMessage(this.translationService.getTranslation('cpp.UnableUploadFile'), error, MessageSeverity.error, errorThrown.stack);

            }
        });
    }
    addUserWin(chat: ChatInfo) {
        let userIds: string[] = [];

        chat.users.forEach(u => userIds.push(u.id))
        this.updateUsersChat.chatId = chat.id;
        this.updateUsersChat.open(userIds);
    }

    updateUserChat(userIds: string[]) {
        this.infoHubService.updateChatUsers(this.updateUsersChat.chatId, userIds)
    }
    deleteGroupChat(chat: ChatInfo) {
        this.alertService.showDialog(`Do you have delete chat '${this.getChatTitle(chat)}'`, DialogType.confirm, () => {
            this.infoHubService.deleteGroupChat(chat.id);
        });


    }
    editTitle(chat: ChatInfo) {
        this.updatetitle.value = this.getChatTitle(chat);
        this.updatetitle.icon = chat.icon;
        this.updatetitle.chatId = chat.id;
        this.updatetitle.open();
    }
    endEditTitle(title: string) {
        this.infoHubService.updateChatTitle(this.activeChat.id, title);

    }
    getChatTitle(chat: ChatInfo) {
        if (!chat) return null;
        let title = chat.title;
        if (!title) title = chat.users.map((u, i, a) => (<any>u).name).join(", ")
        return title;
    }
    getMuteChat(chatId: string, userId: string=null): boolean {
        let setting: ChatUserSetting=null;
        if (userId)
            setting = this.chatSettings.find(c => c.userId == userId);
        if (chatId)
            setting = this.chatSettings.find(c => c.chatId == chatId);
        if (setting == null) return false;
        return setting.disabled;
    }
    muteChat(chatId: string, userId: string=null) {
        let setting: ChatUserSetting = null;
        if (userId)
            setting = this.chatSettings.find(c => c.userId == userId);
        if (chatId)
            setting = this.chatSettings.find(c => c.chatId == chatId);
        if (setting == null) setting = new ChatUserSetting(chatId, userId, false);
        setting.disabled = !setting.disabled; 
        this.infoHubService.updateChatSetting(setting);
    }
     getIconBgImage(chat: ChatInfo) {
         if (chat && chat.icon) {
             //debugger;
             return `url(${this.configurations.baseUrl}/ChatFiles/${encodeURI(chat.icon)})`;
        }
        return null;
    }
}


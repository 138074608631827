<div class="flex">
  <div
    class="user-profile profile-bar"
    
  >
    <!-- User profile image -->
    <div class="profile-img">
          <img [src]="userBgImage"
               class="rounded-circle img-circle icon-50"
               width="50" />

      </div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="profile-text">
      <a [matMenuTriggerFor]="sdprofile"
         class="d-flex align-items-center text-white w-100 f-s-16">
        {{userName}}
        <i-tabler name="chevron-down" class="icon-18 m-l-auto"></i-tabler>
      </a>
    </div>
    <mat-menu #sdprofile="matMenu"
              xPosition="after"
              class="cardWithShadow profile-dd">
      <button mat-menu-item>
        <mat-icon class="d-flex align-items-center">
          <i-tabler name="settings" class="icon-18 d-flex"></i-tabler>
        </mat-icon>
        Settings
      </button>
      <button mat-menu-item>
        <mat-icon class="d-flex align-items-center">
          <i-tabler name="users" class="icon-18 d-flex"></i-tabler>
        </mat-icon>
        Profile
      </button>
      <button mat-menu-item>
        <mat-icon class="d-flex align-items-center">
          <i-tabler name="bell-off" class="icon-18 d-flex"></i-tabler>
        </mat-icon>
        Disable notifications
      </button>
      <!--
        [routerLink]="['/authentication/side-login']" -->
      <a mat-menu-item
         (click)="logout()">
        <mat-icon class="d-flex align-items-center">
          <i-tabler name="logout" class="icon-18 d-flex"></i-tabler>
        </mat-icon>
        Sign Out
      </a>
    </mat-menu>
  </div>
</div>

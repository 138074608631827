<div
  autosize
  autoFocus
  [ngClass]="{
    'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top' &&  !resView,
    'sidebarNav-horizontal': options.horizontal,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme == 'orange_theme',
    blue_theme: options.activeTheme == 'blue_theme',
    aqua_theme: options.activeTheme == 'aqua_theme',
    purple_theme: options.activeTheme == 'purple_theme',
    green_theme: options.activeTheme == 'green_theme',
    cyan_theme: options.activeTheme == 'cyan_theme'
  }"
  [dir]="options.dir!"
>
  <!-- ============================================================== -->
  <!-- VerticalHeader -->
  <!-- ============================================================== -->
  @if(!options.horizontal) {
  <app-header
    [showToggle]="!isOver"
    (toggleCollapsed)="toggleCollapsed()"
    (toggleMobileNav)="sidenav.toggle()"
    (onLogout)="logout()"
  ></app-header>
  } @else {
  <!-- horizontal header -->
  <app-horizontal-header
    (toggleMobileNav)="sidenav.toggle()"
  ></app-horizontal-header>
  }

  <mat-sidenav-container class="mainWrapper">
    <!-- ============================================================== -->
    <!-- Vertical Sidebar -->
    <!-- ============================================================== -->
    @if(!options.horizontal) {
    <mat-sidenav align="start"
                 #leftsidenav
                 [mode]="isOver ? 'over' : 'side'"
                 [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver && !resView"
                 (openedChange)="onSidenavOpenedChange($event)"
                 (closedStart)="onSidenavClosedStart()"
                 class="sidebarNav">
      <!--
      <app-sidebar [userName]="userName"
                   [userBgImage]="userBgImage"
                   (onLogout)="logout()"
                   > </app-sidebar>
        -->
      <div class="position-relative"
                    style="height: calc(100vh - 470px)">
        <mat-nav-list class="sidebar-list">
          @for(item of navItems; track item) {
          <app-nav-item [item]="item" (notify)="sidenav.toggle()" (expand)="expandMenu($event)">
          </app-nav-item>
          }
        </mat-nav-list>
      </div>
      <div class="nav-support " style="height: 470px" >
        <div class="d-flex justify-content-center ">
          <img src="../../../assets/img/v2/support.png" />
        </div>

        <div class="text-center f-s-16 f-w-500">
          Support
        </div>
        <div class="text-center m-10 ">
          NatVision support remains at your disposal for any requests or problems.
        </div>
          <div class="m-10 ">
            <button mat-stroked-button class="text-center contact-buton w-100 m-b-4 f-s-12">
              <mat-icon class="btn-mark">check</mat-icon>
              Available from 8am to 6pm
            </button>
            <button mat-stroked-button class="text-center contact-buton w-100 m-b-4 f-s-12">
              <mat-icon>check</mat-icon>
              From Monday to Friday
            </button>
            <button mat-flat-button color="accent" class="text-center w-100 text-center m-b-4">
              Contact us
            </button>
          </div>

        </div>
</mat-sidenav>
    }

    <!-- ============================================================== -->
    <!-- horizontal Sidebar -->
    <!-- ============================================================== -->
    @if(resView && options.horizontal) {
    <mat-sidenav
      #leftsidenav
      [mode]="'over'"
      [opened]="options.sidenavOpened && !isTablet"
      (openedChange)="onSidenavOpenedChange($event)"
      (closedStart)="onSidenavClosedStart()"
      class="sidebarNav"
    >
      <app-sidebar (onLogout)="logout()"> </app-sidebar>
      <ng-scrollbar
        class="position-relative mini-scroll"
        style="height: calc(100vh - 230px)"
      >
        <mat-nav-list class="sidebar-list">
          @for(item of navItems; track item) {
          <app-nav-item [item]="item" (notify)="sidenav.toggle()">
          </app-nav-item>
          }
        </mat-nav-list>
      </ng-scrollbar>
    </mat-sidenav>
    }

    <!-- ============================================================== -->
    <!-- Main Content -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="contentWrapper">
      @if(options.horizontal) {
      <app-horizontal-sidebar></app-horizontal-sidebar>
      }

      <main
            id="mainContent"
        class="pageWrapper"
        [ngClass]="{
          maxWidth: options.boxed
        }"
      >
        <app-breadcrumb></app-breadcrumb>
        <!-- ============================================================== -->
        <!-- Outlet -->
        <!-- ============================================================== -->
        <router-outlet></router-outlet>
        <div class="customizerBtn">
          <button mat-fab color="primary" (click)="customizerRight.toggle()">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
      </main>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Customizer -->
    <!-- ============================================================== -->
    <mat-sidenav
      #customizerRight
      mode="over"
      position="end"
      class="fixed-customizer"
    >
      <app-customizer (optionsChange)="receiveOptions($event)"></app-customizer>
    </mat-sidenav>
  </mat-sidenav-container>
</div>

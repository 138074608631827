import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';

import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AlertService } from '../alert.service';

@Injectable()
export class GroupPermissionsEndpoint extends EndpointBase {
    private readonly groupUrlPart: string = "/api/GroupPermissions";

    get groupUrl() { return this.configurations.baseUrl + this.groupUrlPart; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }

    getListPermissions<T>(): any {
        let endpointUrl = this.groupUrl;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getListPermissions());
            }));
    }
    getGroupPermissionsEndpoint<T>(groupId: number): any {
        let endpointUrl = this.groupUrl + `/${groupId}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getGroupPermissionsEndpoint(groupId));
            }));
    }
    saveGroupsEndpoint<T>(groupId: number, groupPermissions: T): any {
        let endpointUrl = this.groupUrl + `/${groupId}`;
        return this.http.put<T>(endpointUrl, JSON.stringify(groupPermissions), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.saveGroupsEndpoint(groupId, groupPermissions));
            }));
    }
    
    saveUserCompanyEndpoint<T>(userId: string, companyId: number, groupPermissions: T): any {
        let endpointUrl = this.groupUrl + `/UserExptions/${userId}/Company/${companyId}`;
        return this.http.put<T>(endpointUrl, JSON.stringify(groupPermissions), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.saveUserCompanyEndpoint(userId, companyId, groupPermissions));
            }));
    }
    getUserExceptionsEndpoint<T>(userId?: string, companyId?: number): any {
        var uid = userId ? userId : "";
        var cid = companyId ? companyId : "";
        let endpointUrl = this.groupUrl + `/UserExceptions?userId=${uid}&companyId=${cid}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUserExceptionsEndpoint(userId, companyId));
            }));
    }
}

import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CoreService } from '../../../../services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../sidebar/sidebar-data';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { MaterialModule } from '../../../../material.module';
import { TablerIconsModule } from 'angular-tabler-icons';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BrandingComponent, } from '../sidebar/branding.component';
import { AuthService } from '../../../../services/auth.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import { NotificationService } from '../../../../services/notification.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { AlertService, DialogType, MessageSeverity } from '../../../../services/alert.service';
import { NavService } from '../../../../services/nav.service';
import { Notification } from '../../../../models/notification.model';
import { Utilities } from '../../../../services/utilities';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Company } from '../../../../models/company.model';
import { AccountService } from '../../../../services/account.service';
import { AppTitleService } from '../../../../services/app-title.service';
import { MatSelectModule } from '@angular/material/select';

interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface msgs {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [RouterModule, MaterialModule, TablerIconsModule, FormsModule],
  templateUrl: 'search-dialog.component.html',
})
export class AppSearchDialogComponent {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });
}



@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, NgScrollbarModule, TablerIconsModule, MaterialModule
    , MatButtonToggleModule, FormsModule, MatSelectModule, 
    BrandingComponent, AppSearchDialogComponent],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();
  @Output() onLogout = new EventEmitter<void>();

  showFiller = false;

  notificationsLoadingSubscription: any;
  isUserLoggedIn: boolean;
  dataLoadingConsecutiveFailurs = 0;
  newNotificationCount = 0;
  pinnedNotificationCount = 0;
  rows: Notification[] = [];

  companies: Company[];
  deploymentCode: string;
  deploymentCodes: string[];
  companyId: number;


  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: '/assets/images/flag/icon-flag-en.svg',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
    {
      language: 'Español',
      code: 'es',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'German',
      code: 'de',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },
  ];

  constructor(
    private vsidenav: CoreService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public configurations: ConfigurationService,
    private authService: AuthService,
    public navService: NavService
    , private notificationService: NotificationService
    , private translationService: AppTranslationService
    , private alertService: AlertService
    , private accountService: AccountService
    ,private appTitleService: AppTitleService
    , public router: Router
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.deploymentCodes = this.authService.deployments;

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;

      if (this.isUserLoggedIn) {
        this.authService.companyListChanged();
        this.deploymentCodes = this.authService.deployments;
        this.initNotificationsLoading();
        this.configurations.language = this.authService.currentUser.language;
      }
      else {
        this.unsubscribeNotifications();
      }
    });
    this.authService.getCompanyListChangeEvent().subscribe((v) => {
      this.companyId = this.authService.companyId;
      this.deploymentCode = this.authService.deployment;
      this.deploymentCodes = this.authService.deployments;

      this.accountService.getUserCompanies(this.deploymentCode)
        .subscribe(list => this.setCompany(list));;
    });

  }
  ngOnDestroy() {
    this.unsubscribeNotifications();

  }
  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  logout() {
    console.log(`logout  HeaderComponent`);
    this.onLogout.emit();
  }
  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }
  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : "";
  }
  get userJobTitle(): string {
    return this.authService.currentUser ? this.authService.currentUser.jobTitle : "";
  }
  get userEmail(): string {
    return this.authService.currentUser ? this.authService.currentUser.email : "";
  }
  get userBgImage(): string {

    let picture = this.authService.currentUser ? this.authService.currentUser.picture : "";
    if (picture) {
      return `${this.configurations.baseUrl}/Uploads/UserLogo/${picture}`;
    } else {
      return '';
    }
  }

  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription)
      this.notificationsLoadingSubscription.unsubscribe();
  }

  initNotificationsLoading() {
    let dataLoadTask = this.notificationService.getNotifications(0, 100);
    dataLoadTask.subscribe((notifications) => {
      this.newNotificationCount = notifications.filter(n => !n.isRead).length;
      this.pinnedNotificationCount = notifications.filter(n => n.isPinned).length;
      this.rows = this.processResults(notifications);

    });
    this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically()
      .subscribe(notifications => {
        this.dataLoadingConsecutiveFailurs = 0;
        this.newNotificationCount = notifications.filter(n => !n.isRead).length;
      },
        error => {
          this.alertService.logError(error);

          if (this.dataLoadingConsecutiveFailurs++ < 20)
            setTimeout(() => this.initNotificationsLoading(), 5000);
          else
            this.alertService.showStickyMessage(this.translationService.getTranslation("app.LoadError"),
              this.translationService.getTranslation('main.LoadingNotificationFailed')
              , MessageSeverity.error);
        });
    this.notificationService.getGotoEventEvent().subscribe((item) => {
      // this.popNote.hide();
    })
  }
  markNotificationsAsRead() {

    let recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications.length) {
      this.notificationService.readUnreadNotification(recentNotifications.map(n => n.id), true)
        .subscribe(response => {
          for (let n of recentNotifications) {
            n.isRead = true;
          }

          this.newNotificationCount = recentNotifications.filter(n => !n.isRead).length;
        },
          error => {
            this.alertService.logError(error);
            this.alertService.showMessage(
              this.translationService.getTranslation('main.LoadingNotificationFailed'),
              this.translationService.getTranslation('main.ReadNotificationsFailed'),
              MessageSeverity.error);

          });
    }
  }


  private processResults(notifications: Notification[]) {

    //if (this.isViewOnly) {
      notifications.sort((a, b) => {
        return b.date.valueOf() - a.date.valueOf();
      });
    //}

    return notifications;
  }



  getPrintedDate(value: Date) {
    if (value)
      return Utilities.printTimeOnly(value) + " on " + Utilities.printDateOnly(value);
  }


  deleteNotification(row: Notification) {
    this.alertService.showDialog('Are you sure you want to delete the notification \"' + row.header + '\"?', DialogType.confirm, () => this.deleteNotificationHelper(row));
  }
  openInfo(row: Notification) {
    this.notificationService.openEvent(row);
    return false;
  }

  deleteNotificationHelper(row: Notification) {

    this.alertService.startLoadingMessage("Deleting...");
    //this.loadingIndicator = true;

    this.notificationService.deleteNotification(row)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        //this.loadingIndicator = false;

        this.rows = this.rows.filter(item => item.id != row.id)
      },
        error => {
          this.alertService.stopLoadingMessage();
          //this.loadingIndicator = false;

          this.alertService.showStickyMessage(this.translationService.getTranslation("app.DeleteError"), `An error occured whilst deleting the notification.\r\nError: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }


  togglePin(row: Notification) {

    let pin = !row.isPinned;
    let opText = pin ? "Pinning" : "Unpinning";

    this.alertService.startLoadingMessage(opText + "...");
    //this.loadingIndicator = true;

    this.notificationService.pinUnpinNotification(row, pin)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        // this.loadingIndicator = false;

        row.isPinned = pin;
      },
        error => {
          this.alertService.stopLoadingMessage();
          // this.loadingIndicator = false;

          this.alertService.showStickyMessage(opText + " Error", `An error occured whilst ${opText} the notification.\r\nError: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.error, error);
        });
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }

  getDeploymentTitle(code: string): string {
    return Company.getDeploymentTitle(code);
  }

  setupDeployment(code: string) {
    if (this.authService.deployment != code) {
      this.alertService.showDialog(
        this.translationService.getTranslation('main.SureSwitchEnvironment', { title: this.getDeploymentTitle(code) })
        , DialogType.confirm, () => {
          this.deploymentCode = this.authService.deployment = code;
          this.refresh();

        });

    }
    return false;
  }
  isAllowDeployment(code: string) {
    if (this.deploymentCodes == null) return false;
    return this.deploymentCodes.find(c => c == code) != null;
  }
  changeCpompany() {
    if (this.authService.companyId != this.companyId) {
      var company = this.companies.find(c => c.id == this.companyId);
      this.alertService.showDialog(this.translationService.getTranslation('main.SureSwitchCompany', company), DialogType.confirm, () => {
        this.authService.companyId = this.companyId;
        this.refresh();
      });

    }

  }
  private setCompany(companies: Company[]) {

    let ifSeledecd = false;
    for (let c of companies) {
      if (c.id == this.companyId) {
        ifSeledecd = true;
        break;
      }
    }
    if (!ifSeledecd && companies.length) this.companyId = companies[0].id;
    this.companies = companies;
    this.authService.companyId = this.companyId;
    /*
    setTimeout(() => {
        this.selectCompanies.refresh();
    });
    */
  }
  getCompanyTitle(): string {
    let title = this.companies.find(c => c.id == this.companyId);
    return title?.name;
  }

  refresh() {
    this.authService.sendLoginStatus();
    var isAllow = this.accountService.userHasPermission(this.appTitleService.permission);
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    this.router.navigated = false;
    if (isAllow)
      this.router.navigateByUrl(this.router.url);
    else {
      this.router.navigateByUrl("/");
      this.alertService.showMessage(
        this.translationService.getTranslation('main.AccessDenied'),
        this.translationService.getTranslation('main.NotPermissionPage', this.appTitleService),
        MessageSeverity.warn);

    }

  }


  notifications: notifications[] = [
    {
      id: 1,
      img: '/assets/images/profile/user-1.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
    {
      id: 2,
      img: '/assets/images/profile/user-2.jpg',
      title: 'New message received',
      subtitle: 'Salma sent you new message',
    },
    {
      id: 3,
      img: '/assets/images/profile/user-3.jpg',
      title: 'New Payment received',
      subtitle: 'Check your earnings',
    },
    {
      id: 4,
      img: '/assets/images/profile/user-4.jpg',
      title: 'Jolly completed tasks',
      subtitle: 'Assign her new tasks',
    },
    {
      id: 5,
      img: '/assets/images/profile/user-5.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
  ];

  msgs: msgs[] = [
    {
      id: 1,
      img: '/assets/images/profile/user-1.jpg',
      title: 'Andrew McDownland',
      subtitle: 'Message blocked. Try Again',
    },
    {
      id: 2,
      img: '/assets/images/profile/user-2.jpg',
      title: 'Christopher Jamil',
      subtitle: 'This message cannot be sent',
    },
    {
      id: 3,
      img: '/assets/images/profile/user-3.jpg',
      title: 'Julia Roberts',
      subtitle: 'You are trying to reach location.',
    },
    {
      id: 4,
      img: '/assets/images/profile/user-4.jpg',
      title: 'James Johnson',
      subtitle: 'Assign her new tasks',
    },
    {
      id: 5,
      img: '/assets/images/profile/user-5.jpg',
      title: 'Maria Rodriguez',
      subtitle: 'Congrats for your success',
    },
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'My Profile',
      subtitle: 'Account Settings',
      link: '/',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-inbox.svg',
      title: 'My Inbox',
      subtitle: 'Messages & Email',
      link: '/apps/email/inbox',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-tasks.svg',
      title: 'My Tasks',
      subtitle: 'To-do and Daily Tasks',
      link: '/apps/taskboard',
    },
  ];
}


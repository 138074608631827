import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { Subject, forkJoin, map, tap } from 'rxjs';

import { FileEndpoint } from './file-endpoint.service';
import { AuthService } from './auth.service';
import { UploadFile } from '../models/upload-file.model';
import { PreviewCsv } from '../models/etl/preview-csv.model';

export type UploadFileTypes = "none" | "actu" | "annu" | "assetMaster" | "creditCorrelation"
    | "defaultProbability" | "deterministic" | "erf" | "lossExp" | "assets" | "lossExp1" | "lossExp2"
    | "lossExp3" | "lossExp4" | "lossExp5"
    | "normalCDF" | "recoveryRatesBeta" | "policyFileAlm" | "mortalityTableAlm" | "longevityTableAlm"
    | "factorIndicatorAlm" | "morbidityTableAlm"
    | "lapseTableAlm" | "interestTableAlm" | "guaranteedRatesAlm" | "targetRatesAlm" | "assetsFileAlm"
    | "migrationAlm" | "recoveryAlm" | "scenarioBookValueAlm" | "ratesUpdateFileAlm" | "bookValueCurrentAlm"
    | "capitalCorrAlm" | "customFactorsAlm"
    | "assetMasterMc" | "creditRiskMc" | "recoveryRatesMc" | "policyHolderMc" | "mortalityMc"
    | "longevityMc" | "morbidityMc" | "lapseMc" | "scenarioMc" | "flatExpensesMc" | "percentExpensesMc"
    | "otherMc"
    | "cpp"
    | "ralt"
    ;

export type FilesChangedEventArg = { files: UploadFile[], type: UploadFileTypes };

@Injectable()
export class FileService {
    constructor(private router: Router, private http: HttpClient, private authService: AuthService,
        private fileEndpoint: FileEndpoint) {

    }

    getFiles(types: string[]) {
        return this.fileEndpoint.getFilesEndpoint<UploadFile[]>(types);
    }
    getBigFiles(types: string[]) {
        return this.fileEndpoint.getBigFilesEndpoint<UploadFile[]>(types);
    }
    getFilesByIds(type: string, ids:string[]) {
        return this.fileEndpoint.getFilesByIdsEndpoint<UploadFile[]>(type, ids);
    }

    onChangeFiles(files: UploadFile[]){
        let events = new Map<string, UploadFile[]>();
        for (let file of files) {
            if (!events.has(file.fileType)) {
                events[file.fileType] = [];
            }
            events[file.fileType].push(file)
        }
    }

    deleteFiles(type: string, fileId?: string, subId?: string): Observable<any> {
        return this.fileEndpoint.getDeleteFilesEndpoint<any>(type, fileId, subId);
        
    }
    deleteFile(type: string, fileId?: string): Observable<any> {
        return this.fileEndpoint.getDeleteFileEndpoint<any>(type, fileId);

    }
    getFileChat(id: string) {
        return this.fileEndpoint.getFileChatEndpoint<Blob>(id);

    }
    getFile(type: string, id: string) {
        return this.fileEndpoint.getFileEndpoint<Blob>(type, id);
    }
    getFileText(type: string, id: string) {
      return this.fileEndpoint.getFileEndpoint<Blob>(type, id, true);
    }

    getFileInfo(type: string, id: string) {
        return this.fileEndpoint.getFileInfoEndpoint<UploadFile>(type, id);

    }
    
    getFileProcessedInfo(type: string, id: string) {
        return this.fileEndpoint.getFileProcessedInfoEndpoint<UploadFile>(type, id);

    }
    uploadChatLogo(chatId: string, files: FileList) {
        return this.fileEndpoint.getUploadChatLogoEndpoint<UploadFile>(chatId, files);
    }
    getFileData(type: string, id: string, all: boolean=false) {
        return this.fileEndpoint.getFileDataEndpoint<PreviewCsv>(type, id, all);

    }

}

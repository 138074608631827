export type PermissionNames =
    "Manage Sites Policy" |
    "Rights Assignment" |
    "Asset Liability Modeling" |
    "MCEV" |
    "Audit Policy Details" |
    "Underwriting Risk" |
    "Credit Risk" |
    "Bi NAnalytics" |
    "Audit Trial" |
    "Submit Question" |
    "Remote Support" |
    "Market Risk" |
    "Statutory Reserves" |
    "Batch Scheduler" |
    "Suggest Feature" |
    "Consulting Request" |
    "Portfolio" |
    "Product Definitions" |
    "User Management" |
    "Liability Modeling" |
    "Random Number Generator";

export type PermissionValues =
    "ManageSitesPolicy" |
    "RightsAssignment" |

    "AssetLiabilityModeling" |
    "RAssetLiabilityModeling" |
    "ALMAuditTrailAssets" |
    "ALMAuditTrailLiabilities" |

    "Mcev" |
    "AuditPolicyDetails" |
    "UnderwritingRisk" |
    "CreditRiskPpricing" |
    "FormulasGenerator" |
    "FormulasModelingSpeedUp" |
    "FormulasModelingStandard" |

    "BiNAnalytics" |
    "AuditTrial" |
    "RemoteSupport" |
    "MarketRisk" |
    "TechnicalReserves" |
    "ReservesAuditTrail" |
    "BatchScheduler" |
    "SuggestFunctionality" |
    "EtlTool" |
    "EtlTemplate" |
    "ConsultingRequest" |
    "ChatRoom" | 
    "Portfolio" |
    "ProductDefinitions" |
    "UserManagement" |
    "LiabilityModeling" |
    "GMM" |
    "StressFactors" |
    "Empirical" |
    "OperationalRisk" |
    "UserDocumentation" |
    "FileTemplate" |
    "McevAuditTrail" |
    "UserSettings" |
    "SystemMaintenance" |
    "RandomNumberGenerator" |
    "CppServers" |
    "WorkflowOrchestrator" |
    "GpuEnabled"
;


export enum AccessTypeEnum {
    Read = 1,
    Write = 2
}
export class MyPermission {
    constructor(public companyId?: number, public deployment?: string, public authPolicy?: PermissionValues, public  accessType?: AccessTypeEnum) {
    }
}
export class MainTopPermission {
    constructor(public title?: string, public iconClass?: string, public items?: PermissionValues[]) {
    }
}


export class UserPermission {
    
    constructor(companyId?: number, deployment?:string, value?: PermissionValues, accessType?: AccessTypeEnum) {
        this.value = value;
        this.companyId = companyId;
        this.deployment = deployment;
        this.accessType = accessType;
    }
    public toString(): string {
        return `${this.companyId}_${this.deployment}_${this.value}_${this.accessType}`;
    };
    public Setup(line: string) {
        var parts = line.split("_");
        this.companyId = parseInt(parts[0]);
        this.deployment = parts[1];
        this.value = <PermissionValues>parts[2];
        this.accessType = parseInt(parts[3]);
    };
    public companyId: number;
    public deployment: string;
    public value: PermissionValues;
    public accessType: AccessTypeEnum;

}
export class Permission {

    public static readonly AccessTypeReadPermission: AccessTypeEnum = AccessTypeEnum.Read;
    public static readonly AccessTypeWritePermission: AccessTypeEnum = AccessTypeEnum.Write;

    public static readonly ManageSitesPolicyPermission: PermissionValues = "ManageSitesPolicy";
    public static readonly RightsAssignmentPermission: PermissionValues = "RightsAssignment";

    //public static readonly AssetLiabilityModelingPermission: PermissionValues = "AssetLiabilityModeling";
    public static readonly ALMAuditTrailLiabilitiesPermission: PermissionValues = "ALMAuditTrailLiabilities";
    public static readonly ALMAuditTrailAssetsPermission: PermissionValues = "ALMAuditTrailAssets";


    public static readonly McevPermission: PermissionValues = "Mcev";
    public static readonly McevAuditTrailPermission: PermissionValues = "McevAuditTrail";
    
    public static readonly AssetLiabilityModelingPermission: PermissionValues = "AssetLiabilityModeling";
    public static readonly RAssetLiabilityModelingPermission: PermissionValues = "RAssetLiabilityModeling";


    public static readonly UnderwritingRiskPermission: PermissionValues = "UnderwritingRisk";
    public static readonly GMMPermission: PermissionValues = "GMM";
    public static readonly StressFactorsPermission: PermissionValues = "StressFactors";
    public static readonly EmpiricalPermission: PermissionValues = "Empirical";

    public static readonly CreditRiskPpricingPermission: PermissionValues = "CreditRiskPpricing";

    public static readonly FormulasGeneratorPermission: PermissionValues = "FormulasGenerator";
    public static readonly FormulasModelingSpeedUpPermission: PermissionValues = "FormulasModelingSpeedUp";
    public static readonly FormulasModelingStandardPermission: PermissionValues = "FormulasModelingStandard";
    public static readonly RandomNumberGeneratorPermission: PermissionValues = "RandomNumberGenerator";

    

    public static readonly BiNAnalyticsPermission: PermissionValues = "BiNAnalytics";
    public static readonly AuditTrialPermission: PermissionValues = "AuditTrial";
    public static readonly RemoteSupportPermission: PermissionValues = "RemoteSupport";
    public static readonly MarketRiskPermission: PermissionValues = "MarketRisk";
    public static readonly OperationalRiskPermission: PermissionValues = "OperationalRisk";
    
    public static readonly TechnicalReservesPermission: PermissionValues = "TechnicalReserves";
    public static readonly ReservesAuditTrailPermission: PermissionValues = "ReservesAuditTrail";
    
    public static readonly BatchSchedulerPermission: PermissionValues = "BatchScheduler";
    public static readonly SuggestFunctionalityPermission: PermissionValues = "SuggestFunctionality";
    public static readonly EtlToolPermission: PermissionValues = "EtlTool";
    public static readonly EtlTemplatePermission: PermissionValues = "EtlTemplate";
    
    public static readonly ConsultingRequestPermission: PermissionValues = "ConsultingRequest";
    public static readonly ChatRoomPermission: PermissionValues = "ChatRoom";
    

    public static readonly PortfolioPermission: PermissionValues = "Portfolio";
    public static readonly ProductDefinitionsPermission: PermissionValues = "ProductDefinitions";
    public static readonly UserManagementPermission: PermissionValues = "UserManagement";

    public static readonly UserDocumentationPermission: PermissionValues = "UserDocumentation";
    public static readonly FileTemplatePermission: PermissionValues = "FileTemplate";


    public static readonly UserSettingsPermission: PermissionValues = "UserSettings";
    public static readonly SystemMaintenancePermission: PermissionValues = "SystemMaintenance";
    public static readonly CppServersPermission: PermissionValues = "CppServers";
    public static readonly WorkflowOrchestratorPermission: PermissionValues = "WorkflowOrchestrator";
    public static readonly GpuEnabled: PermissionValues = "GpuEnabled";
    
    


/*
    public static readonly PermissionTitles: { [value: string]: string; } = {
// Administration
        ManageSitesPolicyPermission: "Users' Group & Legal Co./ Lob" ,
        ProductDefinitionsPermission: "Product Definition",
        RightsAssignmentPermission: "User Right Assignment",
        UserManagementPermission: "User Management Console",
        
    };
*/
    public static TopPermission: MainTopPermission[] = [
        new MainTopPermission("Administration", "icon-users", [
            Permission.ManageSitesPolicyPermission,
            Permission.ProductDefinitionsPermission,
            Permission.RightsAssignmentPermission,
            Permission.UserManagementPermission,
            Permission.BatchSchedulerPermission
        ]),
        new MainTopPermission("AssetLiabilityManagement", "fa fa-book", [
            Permission.AssetLiabilityModelingPermission,
            Permission.ALMAuditTrailLiabilitiesPermission,
            Permission.ALMAuditTrailAssetsPermission,
        ]),

        new MainTopPermission("MarketConsistentEmbedded", "fa fa-clipboard", [
            Permission.McevPermission,
            Permission.McevAuditTrailPermission
        ]),
        new MainTopPermission("Reserves", "fa fa-database", [
            Permission.TechnicalReservesPermission,
            Permission.ReservesAuditTrailPermission,
        ]),
        new MainTopPermission("CapitalRequirements", "fa fa-calculator", [
            Permission.GMMPermission,
            Permission.EmpiricalPermission,
            Permission.MarketRiskPermission,
            Permission.CreditRiskPpricingPermission,

        ]),
        /*
                    Permission.GMMPermission,
            Permission.StressFactorsPermission,
            Permission.EmpiricalPermission,

            Permission.OperationalRiskPermission
*/
        new MainTopPermission("FunctionsModels", "icon-chemical", [
            Permission.FormulasGeneratorPermission,
            Permission.FormulasModelingSpeedUpPermission,
            Permission.FormulasModelingStandardPermission
        ]),
        new MainTopPermission("Support", "icon-support", [
            Permission.RemoteSupportPermission,
            Permission.ConsultingRequestPermission,
            Permission.ChatRoomPermission

        ]),
        new MainTopPermission("Help", "icon-help", [
            Permission.SuggestFunctionalityPermission,
            Permission.EtlToolPermission,
            Permission.EtlTemplatePermission,
            Permission.FileTemplatePermission,
            Permission.UserDocumentationPermission,
        ]),
        new MainTopPermission("OtherFunctions", "fa fa-files-o", [
            Permission.UserSettingsPermission,
            Permission.SystemMaintenancePermission
        ]),



    ];




    constructor(name?: string, value?: PermissionValues, groupName?: string, description?: string, accessType?: AccessTypeEnum ) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
        this.accessType = accessType;
    }

    public name: string;
    public value: PermissionValues;
    public accessType: AccessTypeEnum;
    public groupName: string;
    public description: string;
}

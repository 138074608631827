import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company } from '../../models/company.model';
import { CompanyData } from '../../models/companydata.model';
import { CompanyEndpoint } from '../../services/company/company-endpoint.service';
import { BehaviorSubject } from 'rxjs';

export class CompanyChange {
    id: number;
    name: string;
}
@Injectable()
export class CompanyService {
    constructor(private companyEndpoint: CompanyEndpoint) {

    }

    getUserCompanies() {
        return this.companyEndpoint.getUserCompaniesEndpoint<Company[]>();
    }
    getSiteCompanies() {
        return this.companyEndpoint.getSiteCompaniesEndpoint<Company[]>();
    }

    getCompany(id: number) {
        return this.companyEndpoint.getCompanyEndpoint<CompanyData>(id);
    }

    updateCompany(id: number, companyData: CompanyData) {
        if (id)
            return this.companyEndpoint.getUpdateCompanyEndpoint<CompanyData>(companyData, id);
        else
            return this.companyEndpoint.getAddCompanyEndpoint<CompanyData>(companyData);
    }

    deleteCompany(id: number) {
        return this.companyEndpoint.getDeleteCompanyEndpoint<CompanyData>(id);
    }

    private companySource = new BehaviorSubject<CompanyChange>({ id: 0, name: null });
    currentCompany = this.companySource.asObservable();
    companyChanged(id: number, name:string) {
        this.companySource.next({ id: id, name: name });
    }


    private companyListSource = new BehaviorSubject<Company[]>([]);
    companyList = this.companyListSource.asObservable();
    updateCompanies(list: Company[]) {
        this.companyListSource.next(list);
    }
}

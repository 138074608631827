<mat-card class="cardWithShadow overflow-hidden h-100 main-menu-box {{bgBox}} ">
  <mat-card-header>
    <div mat-card-avatar class="header-icon {{bgBoxHeader}}">
      <span mat-card-avatar class="icon-35  text-white  left-coner-icon ">
        <mat-icon class=" icon-36 m-10">
          <i-tabler name="{{ iconName}}" class="icon-36"></i-tabler>
        </mat-icon>
      </span>

    </div>


    <mat-card-title>
      {{ getTransalteMenu(item.displayName)}}

      <span class="makeQestionWithShadow" matTooltip="{{ getTransalteMenu(displayToolTip)}}">

        <span class="char">?</span>
      </span>

    </mat-card-title>
    <mat-card-subtitle>{{ getTransalteMenu(displayTitle)}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="p-b-24">
    <div class="row">
      <div class="col-lg-1"></div>
      <div [ngClass]="{'col-lg-5':image,'col-lg-11':!image}">
        @for(subItem of item.children; track subItem) {
        <a (click)="onItemSelected(subItem)" mat-menu-item class="p-x-14 p-y-8">
          <div class="d-flex align-items-center w-100">
            <span class="icon-30 text-accent rounded-circle d-flex align-items-center justify-content-center">
              <i-tabler name="{{ subItem.iconName }}"
                        class="routeIcon f-s-24"
                        matListItemIcon></i-tabler>



            </span>
            <div class="m-l-16">
              <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
                {{ 'mainMenu.'+subItem.displayName | translate}}
              </h5>
            </div>
          </div>
        </a>
        }

      </div>
      @if(image){
      <div class="col-lg-5 home-menu-image">
        @if(image=="calc"){
        <img cl src="../../../assets/img/v2/home/calc.png" class="ms-auto my-auto" />
        }
        @if(image=="pc"){
        <img src="../../../assets/img/v2/home/pc.png" class="ms-auto mt-auto" />
        }
        @if(image=="cash"){
        <img src="../../../assets/img/v2/home/cash.png" class="ms-auto mt-auto" />
        }
        @if(image=="document"){
        <img src="../../../assets/img/v2/home/document2.png" class="ms-auto mt-auto" />
        }

        @if(image=="search"){
        <img src="../../../assets/img/v2/home/search.png" class="ms-auto mt-auto" />
        }
        @if(image=="chart"){
        <img src="../../../assets/img/v2/home/arrow-graph2.png" class="ms-auto mt-auto" />
        }
      </div>
      }
    </div>


</mat-card-content>

</mat-card>




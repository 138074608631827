export class Company {
    constructor(public id?: number, public name?: string, public deployment?: string) {
    }
    static getDeploymentTitle(code: string) {
        switch (code) {
            case 'P': return 'Production';
            case 'D': return 'Development';
            case 'T': return 'Test';
            default: return '';
        }
    }
}
export class CompanySelect {
    constructor(public id?: number, public name?: string, public isEnable?: boolean, public deployment?: string) {
    }
}
<div class="loginPage loginModal">
  <div class="loginFormWrapper txtCenter">
    <div class="loginPage_top">
      <img src="../../assets/img/logo.png" alt="StochaC logo" class="loginPage_logo">
      <h1 class="loginPage_accroche">Stochastic Processing <br>at the Speed of Light</h1>
    </div>
    @if (user) {
      <form class="loginForm js-loginForm" name="restForm" #f="ngForm" novalidate
        (ngSubmit)="f.form.valid ? save() :
        ( !newPassword.valid && showErrorAlert('New password is required', 'Please enter the new password (minimum of 6 characters)' ));
        ( confirmPassword.errors?.required && showErrorAlert('Confirmation password is required', 'Please enter the confirmation password' ));
        (confirmPassword.errors?.validateEqual && showErrorAlert('Passwword mismatch', 'New password and comfirmation password do not match' ));">
        @if (user) {
          <h1 class="loginPage_accroche">Setup new password for <strong>{{user.fullName}}</strong></h1>
        }
        <div class="loginForm_inputWrapper"
          [ngClass]="{'has-success': f.submitted && newPassword.valid, 'has-error' : f.submitted && !newPassword.valid}">
          <input type="password" id="newPassword" name="newPassword"
            autocomplete="off"
            placeholder="Enter new password" class="form-control"
            [(ngModel)]="userEdit.newPassword"
            #newPassword="ngModel"
            required minlength="6" validateEqual="confirmPassword" reverse="true" />
            @if (f.submitted) {
              <span class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': newPassword.valid, 'glyphicon-remove' : !newPassword.valid}" aria-hidden="true"></span>
            }
            @if ( f.submitted && !newPassword.valid) {
              <span class="errorMessage">
                Password is required
              </span>
            }
          </div>
          <div class="loginForm_inputWrapper" [ngClass]="{'has-success': f.submitted && confirmPassword.valid, 'has-error' : f.submitted && !confirmPassword.valid}">
            <input type="password" attr.id="confirmPassword" name="confirmPassword"
              autocomplete="off"
              placeholder="Confirm new password" class="form-control"
              [(ngModel)]="userEdit.confirmPassword" #confirmPassword="ngModel"
              required validateEqual="newPassword" />
              @if (f.submitted) {
                <span class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': confirmPassword.valid, 'glyphicon-remove' : !confirmPassword.valid}" aria-hidden="true"></span>
              }
              @if ( f.submitted && confirmPassword.errors?.required) {
                <span class="errorMessage">
                  Confirmation Password is required
                </span>
              }
              @if ( f.submitted && confirmPassword.errors?.validateEqual) {
                <span class="errorMessage">
                  Password Mismatch
                </span>
              }
            </div>
            <button type="submit" class="s-btn br4 txt18 mtop40 s-btnOrange" [disabled]="isLoading">@if (isLoading) {
              <i class='fa fa-circle-o-notch fa-spin'></i>
            } {{isLoading ? 'Saveing...' : 'Save Password'}}</button>
          </form>
        }
      </div>
    </div>
    
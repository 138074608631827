<div class="{{'loginPage loginModal'}}">


  <div class="blank-layout-container justify-content-center align-items-center">
    <div class="position-relative row w-100 h-100 bg-gredient justify-content-center">
      <div class="col col-lg-4 col-md-6 col-sm-12  align-items-center m-auto">

        <app-login></app-login>


      </div>
    </div>
  </div>
</div>


import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AlertService } from '../alert.service';
import { ConverSetting } from '../../models/etl/conver-setting.model';

@Injectable()
export class EtlEndpoint extends EndpointBase {

    private readonly etlUrlPart: string = "/api/Etl";

    get etlUrl() { return this.configurations.baseUrl + this.etlUrlPart; }

    constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector, alertService: AlertService) {
        super(http, configurations, injector, alertService);
    }

    getMappingsEndpoint<T>(): Observable<T> {
        let endpointUrl = this.etlUrl;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getMappingsEndpoint());
            }));
    }
    getDataFormatsEndpoint<T>(): Observable<T> {
        let endpointUrl = this.etlUrl+"/dateformats";
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getDataFormatsEndpoint());
            }));
    }
    getUpdateMappingEndpoint<T>(id: string, mapping: any): Observable<T> {
        let endpointUrl = this.etlUrl + `/${id}`;
        return this.http.put<T>(endpointUrl, JSON.stringify(mapping), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getAddMappingEndpoint(mapping));
            }));
    }
    getAddMappingEndpoint<T>(mapping: any): Observable<T> {
        let endpointUrl = this.etlUrl;
        return this.http.post<T>(endpointUrl, JSON.stringify(mapping), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getAddMappingEndpoint(mapping));
            }));
    }
    getPreviewConvertMappingEndpoint<T>(mapping: any): Observable<T> {
        let endpointUrl = this.etlUrl +"/output/convert/preview";
        return this.http.put<T>(endpointUrl, JSON.stringify(mapping), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getPreviewConvertMappingEndpoint(mapping));
            }));
    }
    getConvertFileEndpoint<T>(converSetting: ConverSetting): Observable<T> {
        let endpointUrl = this.etlUrl + "/output/convert";;
        return this.http.post<T>(endpointUrl, JSON.stringify(converSetting), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getConvertFileEndpoint(converSetting));
            }));
    }
    getOutputFilePreviewEndpoint<T>( id: any, ): Observable<T> {
        let endpointUrl = this.etlUrl + `/output/${id}/preview`;
        return this.http.get<T>(endpointUrl,  this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getOutputFilePreviewEndpoint(id));
            }));
    }

    getPatternsEndpoint<T>(): Observable<T> {
        let endpointUrl = this.etlUrl + "/patterns";
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getPatternsEndpoint());
            }));
    }
    getPatternEndpoint<T>(id): Observable<T> {
        let endpointUrl = this.etlUrl + `/patterns/${id}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getPatternEndpoint(id));
            }));
    }
    getAddPatternEndpoint<T>(item): Observable<T> {
        let endpointUrl = this.etlUrl + `/patterns`;
        return this.http.post<T>(endpointUrl, JSON.stringify(item),  this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getAddPatternEndpoint(item));
            }));
    }
    getUpdatePatternEndpoint<T>(id, item): Observable<T> {
        let endpointUrl = this.etlUrl + `/patterns/${id}`;
        return this.http.put<T>(endpointUrl, JSON.stringify(item), this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getUpdatePatternEndpoint(id, item));
            }));
    }
    getMappingEndpoint<T>(id): Observable<T> {
        let endpointUrl = this.etlUrl + `/${id}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getMappingEndpoint(id));
            }));
    }
    getDeleteMappingEndpoint<T>(id): Observable<T> {
        let endpointUrl = this.etlUrl + `/${id}`;
        return this.http.delete<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getDeleteMappingEndpoint(id));
            }));
    }

    getFileOutputEndpoint<T>(id): Observable<T> {
        let endpointUrl = `${this.etlUrl}/output/${id}`;
        let options = <any>this.getRequestHeaders()
        options.responseType = "blob";
        return this.http.get<T>(endpointUrl, options)
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getFileOutputEndpoint(id));
            }));
    }
    getInputFilesEndpoint<T>(type): Observable<T> {
        let endpointUrl = `${this.etlUrl}/input/files?type=${type}`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getInputFilesEndpoint(type));
            }));

    }
    getFileInputInfoEndpoint<T>(id): Observable<T> {
        let endpointUrl = `${this.etlUrl}/input/${id}/info`;
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getFileInputInfoEndpoint(id));
            }));

    }
    getDeleteInputFileEndpoint<T>(id): Observable<T> {
        let endpointUrl = `${this.etlUrl}/input/${id}`;
        let options = <any>this.getRequestHeaders()
        return this.http.delete<T>(endpointUrl, options)
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getDeleteInputFileEndpoint(id));
            }));
    }
    getFileIntputEndpoint<T>(id): Observable<T> {
        let endpointUrl = `${this.etlUrl}/input/${id}`;
        let options = <any>this.getRequestHeaders()
        options.responseType = "blob";
        return this.http.get<T>(endpointUrl, options)
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getFileIntputEndpoint(id));
            }));
    }
    getResultFilesEndpoint<T>(mappingId): Observable<T> {
        let endpointUrl = `${this.etlUrl}/input`;
        if (mappingId)
            endpointUrl += `?mappingId=${mappingId}`;

        let options = <any>this.getRequestHeaders()
        return this.http.get<T>(endpointUrl, options)
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getResultFilesEndpoint(mappingId));
            }));
    }
    getFileIntputPreviewEndpoint<T>(id, setting): Observable<T> {
        let endpointUrl = `${this.etlUrl}/input/${id}/preview`;
        return this.http.post<T>(endpointUrl, setting, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getFileIntputPreviewEndpoint(id, setting));
            }));
    }
    testDbConnectionEndpoint<T>(setting): Observable<T> {
        let endpointUrl = `${this.etlUrl}/import/testConnection`;
        return this.http.post<T>(endpointUrl, setting, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.testDbConnectionEndpoint(setting));
            }));
    }
    importDbConnectionEndpoint<T>(setting): Observable<T> {
        let endpointUrl = `${this.etlUrl}/import/importConver`;
        return this.http.post<T>(endpointUrl, setting, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.importDbConnectionEndpoint(setting));
            }));
    }
    importPreviewEndpoint<T>(setting): Observable<T> {
        let endpointUrl = `${this.etlUrl}/import/preview`;
        return this.http.post<T>(endpointUrl, setting, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.importPreviewEndpoint(setting));
            }));
    }
    getDbSettingsEndpoint<T>(types:string[]): Observable<T> {
        let endpointUrl = `${this.etlUrl}/import/setting/` + types.join(',');
        return this.http.get<T>(endpointUrl, this.getRequestHeaders())
            .pipe(catchError(error => {
                return this.handleError(error, () => this.getDbSettingsEndpoint(types));
            }));
    }
}

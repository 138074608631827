import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PortfolioModel } from '../../models/company/portfolio.model';
import { BehaviorSubject } from 'rxjs';
import { UserGroup } from '../../models/Security/user-group.model';
import { SecurityEndpoint } from './security-endpoint.service';
import { CompanyUserGroup } from '../../models/Security/company-user-group.model';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class SecurityService {
    constructor(private securityEndpoint: SecurityEndpoint) {
    }
    getGroups() {
        return this.securityEndpoint.getGroupsEndpoint<UserGroup[]>();
    }
    addGroup(group: UserGroup) {
        return this.securityEndpoint.addGroupEndpoint<UserGroup>(group);
    }
    deleteGroup(id: number) {
        return this.securityEndpoint.deleteGroupEndpoint<UserGroup>(id);
    }
    saveGroups(groups: UserGroup[]) {
        return this.securityEndpoint.saveGroupsEndpoint<UserGroup[]>(groups);
    }
    getGroup(id:number) {
        return this.securityEndpoint.getGroupEndpoint<UserGroup[]>(id);
    }

    private groupsSubject = new Subject<boolean>();
    notifyGroupsChanged() {
        this.groupsSubject.next(null);
    }
    getGroupsEvent() {
        return this.groupsSubject.asObservable();
    }
}
